import RoleLayout from '@/views/aiCustomization/Layout.vue';
// 角色入驻
export default [
	{
		path: '/aiCustomization',
		component: RoleLayout,
		name: 'aiCustomization',
		redirect: '/aiCustomization/aiCustomizationIndex',
		children: [
			{
				path: 'aiCustomizationIndex',
				component: () => import('@/views/aiCustomization/aiCustomizationIndex/index'),
				meta: {
					title: 'AI定制介绍',
				},
			},
		
		],
	},
];