import Vue from 'vue';
import login from './index.vue';
import store from '@/store';
const loginConstructor = Vue.extend(login);

let instance;
const initInstance = (obj) => {
	instance = new loginConstructor({
		el: document.createElement('div'),
		data() {
			return {
				...obj,
			};
		},
	});
};

const showLogin = (obj) => {
	return new Promise((resolve, reject) => {
		initInstance(obj);
		document.body.appendChild(instance.$el);
		Vue.nextTick(() => {
			if (store.getters.isLogin) {
				resolve();
			} else {
				instance.loginDialogShow = true;
			}
		});

		instance.callback = (action) => {
			instance.$el.parentNode.removeChild(instance.$el);
			if (action === 'confirm') {
				resolve();
			} else if (action === 'cancel') {
				reject();
			}
		};
	});
};

Vue.prototype.$login = showLogin;
