<template>
	<div class="role-enter">
		<role-header />
		<div class="role-main">
			<router-view />
		</div>
		<role-footer />
	</div>
</template>

<script>
import RoleHeader from './components/RoleEnter/RoleHeader.vue';
import RoleFooter from './components/RoleEnter/RoleFooter.vue';
export default {
	name: 'Layout',
	components: {
		RoleHeader,
		RoleFooter,
	},
	data() {
		return {};
	},
	methods: {},
};
</script>

<style lang="scss">
.role-main {
	padding-top: 122px;
	background-color: white;
	.w-1200 {
		width: 1200px;
		margin: auto;
	}
	.role-index-h1 {
		font-family: PingFang SC;
		font-size: 34px;
		font-weight: 600;
		line-height: 44px;
		text-align: center;
		.h1-space {
			display: inline-block;
			padding-right: 25px;
		}
	}
}
</style>
