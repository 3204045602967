<template>
	<el-dialog
		:modal-append-to-body="false"
		:close-on-click-modal="false"
		title=""
		:visible.sync="dialogVisible"
		:width="width"
		custom-class="dialogBox"
		:show-close="false"
		:append-to-body="appendToBody"
		@close="close"
	>
		<div slot="title" v-if="showHeader">
			<slot name="header_title"> {{ title }}</slot>
			<div class="closeIcon" @click="dialogVisible = false">
				<i class="icon-sohu-guanbi1"></i>
			</div>
		</div>
		<slot> </slot>
		<div class="bottom_btn" v-if="showBottomBtn">
			<slot name="bottom_btn">
				<el-button class="button" @click="cancel">取消</el-button>
				<el-button class="button confirm" @click="confirm">{{
					confirmText
				}}</el-button>
			</slot>
		</div>
	</el-dialog>
</template>
<script>
export default {
	props: {
		value: {
			type: Boolean,
		},
		showHeader: {
			type: Boolean,
			default: true,
		},
		appendToBody: {
			type: Boolean,
			default: false,
		},
		width: {
			type: String,
			default: '500px',
		},
		// 底部按钮是否显示
		showBottomBtn: {
			type: Boolean,
			default: false,
		},
		// 	确定按钮文字
		confirmText: {
			type: String,
			default: '确定',
		},
		title: {
			type: String,
			default: '标题',
		},
	},
	data() {
		return {
			dialogVisible: false,
			loading: false,
		};
	},
	watch: {
		value: {
			handler(val) {
				this.dialogVisible = val;
			},
		},
	},
	methods: {
		// 关闭前的回调
		close() {
			this.loading = false;
			this.$emit('input', false);
		},

		// 取消按钮
		cancel() {
			this.close();
		},

		// 确定按钮
		confirm() {
			if (this.loading) return;
			this.loading = true;
			this.$emit('confirm', (state) => {
				if (state) return (this.loading = false);
				this.close();
			});
		},
	},
};
</script>
<style lang="scss">
.dialogBox {
	background: #ffffff;
	overflow: hidden;
	&.el-dialog {
		border-radius: 16px;
		padding: 32px;
		.el-dialog__header {
			padding: 0;
			& > div {
				display: flex;
				justify-content: space-between;
				align-items: center;
				width: 100%;
				color: #3d3d3d;
				font-size: 16px;
				font-weight: 500;
				.closeIcon {
					font-size: 32px;
					color: #333333;
					cursor: pointer;
					&:hover {
						opacity: 0.8;
					}
				}
			}
		}
		.el-dialog__body {
			padding: 0;
			.bottom_btn {
				display: flex;
				justify-content: center;
				align-items: center;
				position: sticky;
				bottom: 0;
				background-color: #fff;
				padding-top: 20px;
				.button {
					border-radius: 4px;
					width: 100px;
					height: 40px;
					color: #3d3d3d;
					font-weight: 500;
					font-size: 16px;
					border: 0 solid #000;
					background: #f3f3f3;
					padding: 0;
					&.confirm {
						background: #ff6c27;
						color: #ffffff;
					}
					&:hover {
						opacity: 0.8;
					}
				}
			}
		}
	}
}
</style>
