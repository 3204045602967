import { deleteApi, get, postFile, postJson, putJson } from '@/utils/request';
export default {
	// 查询子任务分页列表-任务广场
	busyTaskTaskPage(data) {
		return get(`/busy-order/app/busy/task/task/page`, data);
	},
	// 查询分类列表
	commonCategoryList(data) {
		return get(`/app/api/common/category/list`, data);
	},
	// 查询分类列表
	queryIndustry(data) {
		return get(`/app/api/common/industry/getAllTrees`, data);
	},
	// 获取子任务主体详细信息
	busyOrderAppBusyTaskChild(taskNumber, data) {
		return get(`/busy-order/app/busy/task/child/${taskNumber}`, data);
	},
	// 获取任务关联详情
	taskReceiveDetails(id, data) {
		return get(`/busy-order/busy/task/receive/${id}`, data);
	},
	//任务接单
	busyTaskReceive(data) {
		return postJson(`/busy-order/app/busy/task/receive`, data);
	},

	// 查询接单方申请接单列表-接单方
	busyTaskReceiveReceiveList(data) {
		return get(`/busy-order/app/busy/task/receive/receive/list`, data);
	},

	//申请app定制
	addDiyApply(data) {
		return postJson(`/app/operate/addDiyApply`, data);
	},

	//用户余额
	orderpayBalance() {
		return get(`/app/user/balance`);
	},

	// 代理分账单列表
	independentAccountList(data) {
		return get('/pay/independentOrder/list', data);
	},
	//提现列表
	billList(data) {
		return get('/pay/accountBank/bill/list', data);
	},
	//验证支付密码
	verifyPayPwd(data) {
		return get('/pay/accountBank/pay/password', data);
	},
	//申请提现
	withdrawal(data) {
		return postJson(`/pay/accountBank/withdrawal`, data, '', false);
	},
	// 获取任务阶段性交付详细信息-任务方非阶段性
	busyTaskDeliveryNoTask(taskNumber, data) {
		return get(
			`/busy-order/app/busy/task/delivery/no/task/${taskNumber}`,
			data,
		);
	},
	//修改商单接单
	editBusyTaskReceive(data) {
		return putJson(`/busy-order/app/busy/task/receive`, data);
	},
	//获取任务类型
	categoryList(data) {
		return get(`/app/api/common/category/list`, data);
	},

	//非阶段性任务交付
	busyTaskDeliveryOver(data) {
		return postJson(`/busy-order/app/busy/task/delivery/over`, data);
	},
	// 获取任务阶段性交付详细信息-任务方阶段性
	busyTaskDeliveryTask(taskNumber) {
		return get(`/busy-order/app/busy/task/delivery/task/${taskNumber}`);
	},

	// 赚钱视频列表
	businessVideoList(data) {
		return get(`/app/api/business/video/list`, data);
	},

	// 赚钱图文列表
	businessArticleList(data) {
		return get(`/app/api/business/article/list`, data);
	},
	payAccountBankBank(data) {
		return get(`/pay/accountBank/bank`, data);
	},

	//获取验证码(查看证件号)
	getCodePrivacy(data) {
		return postJson(`/resource/sms/code/privacy`, data);
	},

	//银行卡验证返回未加密信息
	getInfoByBankSms(data) {
		return get(`/pay/accountBank/getInfoBySms`, data);
	},

	// 赚钱问答列表
	businessQuestionList(data) {
		return get(`/app/api/business/question/list`, data);
	},
	// 返回每个标签下前五的视频
	videoLabelFive(data) {
		return get(`/app/api/content/video/label/top/five`, data);
	},

	// 返回每个标签下前五的图文
	articleLabelFive(data) {
		return get(`/app/api/content/article/label/top/five`, data);
	},

	// 返回每个标签下前五的问答
	questionLabelFive(data) {
		return get(`/app/question/label/top/five`, data);
	},

	//赚钱产品列表
	shareProductList(data) {
		return get(`/admin/shareProduct/list`, data);
	},
	//赚钱产品详情
	shareProductDetail(id) {
		return get(`/admin/api/shareApply/shareProduct/${id}`);
	},
	//赚钱产品分享
	produnctShareApply(data) {
		return postJson(`/admin/api/shareApply`, data);
	},

	// 
};
