<template>
	<dialogBox
		appendToBody
		:showHeader="false"
		v-model="foxCoinDialogShow"
		width="390px"
		class="foxCoinDialog"
	>
		<div class="foxCoinBox">
			<div class="foxCoinHeard">
				<div class="title">
					狐币充值<span>余额：{{ userCoinInfo.virtualCoin }}</span>
				</div>
				<i @click="cancelClick" class="icon-sohu-quxiao"></i>
			</div>
			<div class="money-box">
				<div
					v-for="(item, index) in moneyList"
					class="money-item"
					:class="{
						active: moneyIndex == index,
					}"
					@click="handleClick(index)"
				>
					<template v-if="index !== lastIndex">
						<p>
							<span>{{ item.coin }}</span
							>狐币
						</p>
						<p>{{ item.amount }}元</p>
					</template>
					<template v-else>
						<div v-if="money">
							<p>
								<span :style="{fontSize: money == 1000000 || money > 1000000?'18px':'20px'}">{{ money * 10 }}</span
								>狐币
							</p>
							<p>
								{{ money }}元<span class="eidt-btn" @click.stop="handleEdit"
									>修改</span
								>
							</p>
						</div>
						<div v-else class="custom">
							<p>自定义金额</p>
							<p>最高100万元</p>
						</div>
					</template>
				</div>
				<div v-if="5 == moneyIndex && isShow" class="input-main">
					<div class="inputBox">
						￥
						<el-input
							ref="searchRef"
							v-model="money"
							 maxlength="7"
							@input="validateNumber"
							placeholder="输入充值金额"
						>
						</el-input>
					</div>
					<div class="bottom">
						<span>{{ Number(money) * 10 || 0 }}狐币</span>
						<div class="btnBox">
							<el-button @click="handleCancel">取消</el-button>
							<el-button @click="handleSure">确认</el-button>
						</div>
					</div>
				</div>
			</div>
			<template v-if="moneyIndex > -1">
				<div class="scan-code-title">扫码支付</div>
				<div class="scancodeBox">
					<div class="leftBox" v-loading="!qrcodeVal">
						<vue-qr
							style="margin: auto; display: block"
							:text="qrcodeVal"
							class="code"
							:margin="10"
							v-if="qrcodeVal"
						></vue-qr>
						<p class="refresh" @click="addOrder()">刷新二维码</p>
						<div class="mask" v-show="isMaskShow"></div>
					</div>
					<div class="rightBox">
						<p class="amount-box">
							<span class="payable">应付金额</span><span class="symbol">￥</span
							><span class="amount" :style="{fontSize: money == 100000 || money > 100000?'25px':'30px'}">{{
								moneyIndex == lastIndex?this.money || 0:moneyList[moneyIndex].amount || 0
							}}</span>
						</p>
						<p class="pay-way">
							<i class="icon-sohu-weixinzhifu"></i>使用微信支付
						</p>
						<p class="protocol" @click="goAgree">
							已阅读并同意<span>《充值服务协议》</span>
						</p>
						<div class="pay-finish" @click="payFinish">我已支付</div>
					</div>
				</div>
			</template>
		</div>
	</dialogBox>
</template>
<script>
import dialogBox from '@/components/dialogBox.vue';
import VueQr from 'vue-qr';
export default {
	components: { dialogBox, VueQr },
	data() {
		return {
			foxCoinDialogShow: false,
			moneyList: [],
			moneyIndex: -1,
			money: null,
			isShow: false,
			userCoinInfo: {},
			qrcodeVal: '',
		};
	},
	created() {
		this.getRechargeList();
		this.getRechargeVirtual();
	},
	computed:{
		lastIndex(){
			return this.moneyList.length - 1
		},
		isMaskShow(){
			return this.isShow || (!this.isShow && !this.money && this.moneyIndex == this.lastIndex)
		}
	},
	methods: {
		// 验证输入金额
		validateNumber(val) {
			var regex = /^[1-9]\d*$/;
			if (!regex.test(val)) {
				this.money = val.replace(/[^1-9]/g, '');
			}else{
				if (this.money > 1000000) {
					this.$message.error('最高充值100万，请重新输入');
					this.money = 1000000;
				}
			}
		},
		// 获取充值列表
		async getRechargeList() {
			let { code, data } = await this.$http.rechargeList();
			if (code == 200) {
				this.moneyList = data;
				this.moneyList.push({});
				this.moneyIndex = 0;
				this.addOrder();
			}
		},
		// 获取狐币余额
		async getRechargeVirtual() {
			let { code, data } = await this.$http.rechargeVirtual();
			if (code == 200) {
				this.userCoinInfo = data;
			}
		},
		// 切换重置金额
		handleClick(index) {
			this.moneyIndex = index;
			if (index == this.lastIndex) {
				this.isShow = true;
			} else {
				this.money = null;
				this.isShow = false;
				this.addOrder();
			}
		},
		// 修改金额
		handleEdit() {
			this.isShow = true;
		},
		// 关闭重置弹窗
		cancelClick() {
			this.foxCoinDialogShow = false;
		},
		// 创建订单
		async addOrder(type) {
			this.qrcodeVal = null;
			let params = {
				payType: 'yi-ma',
				payChannel: 'pc',
				paySource: 9,
			}
			if(type || this.money){
				params.amount = this.money;
			}else{
				params.masterId = this.moneyList[this.moneyIndex].id;
			}
			let res = await this.$http.ordersAdded(params);
			if (res.code == 200) {
				let msg = JSON.parse(res.msg);
				this.qrcodeVal = msg.codeUrl;
			}
		},
		// 我已支付
		payFinish() {
			this.callback('confirm');
			this.foxCoinDialogShow = false;
		},
		// 阅读协议
		goAgree() {
			window.open(
				'https://world.sohuglobal.com/协议/余额充值服务协议.html',
				'_blank',
			);
		},
		// 自定义金额确定
		handleSure() {
			if (this.money) {
				this.isShow = false;
				this.addOrder('custom');
			} else {
				this.$message.error('请输入金额');
			}
		},
		// 自定义金额取消
		handleCancel() {
			this.money = null;
			this.isShow = false;
		},
	},
};
</script>
<style lang="scss" scoped>
.foxCoinBox {
	background: #fff;
	width: 350px;
	max-height: 414px;
	display: flex;
	flex-direction: column;
	padding: 20px;
	.foxCoinHeard {
		position: relative;
		.title {
			color: #3d3d3d;
			font-size: 18px;
			font-weight: 600;
			span {
				font-size: 12px;
				color: #999;
				margin-left: 10px;
			}
		}
		.icon-sohu-quxiao {
			position: absolute;
			right: 0;
			font-size: 12px;
			top: 0;
			cursor: pointer;
		}
	}
	.money-box {
		margin-top: 14px;
		display: grid;
		gap: 10px;
		position: relative;
		grid-template-rows: repeat(2, 68px);
		grid-template-columns: repeat(3, 110px);
		.input-main {
			position: absolute;
			z-index: 999;
			right: 0;
			width: 215px;
			height: 80px;
			border-radius: 8px;
			background: #ffffff;
			bottom: -140px;
			padding: 20px;
			box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
			.inputBox {
				border-radius: 4px;
				background: #f5f5f5;
				display: flex;
				align-items: center;
				padding: 0 12px;
				font-size: 14px;
				color: #999;
				:deep(.el-input__inner) {
					background: #f5f5f5;
					border: 0px solid #000;
					padding: 0;
					padding-left: 5px;
				}
			}
			.bottom {
				display: flex;
				margin-top: 20px;
				align-items: center;
				justify-content: space-between;
				font-size: 12px;
				.btnBox {
					display: flex;
					.el-button {
						width: 50px;
						height: 28px;
						display: flex;
						justify-content: center;
						align-items: center;
						font-size: 12px;
						&:first-child {
							background: #f5f5f5;
						}
						&:last-child {
							background: $theme-color;
							border: none;
							color: #fff;
						}
					}
				}
			}
		}
		.money-item {
			border-radius: 12px;
			background: #f5f5f5;
			text-align: center;
			font-size: 12px;
			cursor: pointer;
			P {
				&:first-child {
					color: #ff2443;
					margin-top: 16px;
					span {
						font-size: 20px;
						font-weight: 600;
					}
				}
				&:last-child {
					color: #999;
					margin-top: 5px;
					.eidt-btn {
						color: #ff2443;
						margin-left: 4px;
					}
				}
			}
			.custom {
				p {
					&:first-child {
						font-size: 14px;
						font-weight: 600;
						color: #333333;
					}
				}
			}
		}
		.active {
			border: 2px solid $theme-color;
		}
	}
	.scan-code-title {
		color: #3d3d3d;
		font-size: 18px;
		font-weight: 600;
		margin: 24px 0 14px;
	}
	.scancodeBox {
		width: 334px;
		height: 136px;
		border-radius: 12px;
		background: #f5f5f5;
		display: flex;
		gap: 8px;
		padding: 8px;
		.leftBox {
			width: 136px;
			height: 120px;
			border-radius: 8px;
			position: relative;
			background: #ffffff;
			text-align: center;
			padding: 8px 0;
			.code {
				width: 100px;
			}
			.refresh {
				font-size: 12px;
				color: $theme-color;
				margin-top: 5px;
				cursor: pointer;
			}
			.mask {
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				background: rgba(0, 0, 0, 0.4);
			}
		}
		.rightBox {
			.amount-box {
				font-size: 18px;
				font-weight: 500;
				color: #3d3d3d;
				.symbol {
					font-weight: 600;
					color: #ff2443;
				}
				.amount {
					font-size: 30px;
					font-weight: 600;
					color: #ff2443;
				}
			}
			.pay-way {
				font-size: 14px;
				color: rgba(0, 0, 0, 0.9);
				margin-top: 19px;
				i {
					color: #43c93e;
					margin-right: 5px;
				}
			}
			.protocol {
				font-size: 11px;
				color: #999999;
				cursor: pointer;
				margin-top: 15px;
				span {
					color: $theme-color;
				}
			}
			.pay-finish {
				width: 85px;
				height: 30px;
				border-radius: 4px;
				text-align: center;
				line-height: 30px;
				cursor: pointer;
				background: $theme-color;
				font-size: 12px;
				font-weight: 500;
				color: #ffffff;
				margin-top: 15px;
			}
		}
	}
}
:deep(.el-dialog) {
	border-radius: 12px;
	padding: 0;
	box-shadow: none;
}
:deep(.el-loading-spinner .path){
  stroke: $theme-color;
}
</style>
