<template>
	<el-container class="layout">
		<el-header height="72px"> <q-header></q-header></el-header>
		<el-container style="overflow: auto">
			<backTop @gotoTop="gotoTop" />
			<el-aside width="180px"><q-aside></q-aside></el-aside>
			<el-main style="background-color: #f8f8fc">
				<div
					style="height: 100%; overflow: auto"
					@scroll="handleScroll"
					class="main-content"
				>
					<KeepAlive include="imageTextList,questionList,videoList" >
						<router-view />
					</KeepAlive>
				</div>
			</el-main>
		</el-container>
	</el-container>
</template>
<script>
import qAside from './q-aside.vue';
import qHeader from './q-header.vue';
import VueEmit from '@/utils/VueEmit';
import backTop from '@/components/backTop';
export default {
	components: {
		qAside,
		qHeader,
		backTop,
	},
	data() {
		return {};
	},
	watch: {
		$route: {
			handler(val, oldVal) {
				const container = document.querySelector('.main-content');
				container.scrollTop = 0;
				VueEmit.$off('scrolltolower');
			}
		},
	},
	methods: {
		// 监听界面滚动
		handleScroll(event) {
			const scrollTop = event.target.scrollTop;
			const scrollHeight = event.target.scrollHeight;
			const clientHeight = event.target.clientHeight;
			if (scrollHeight - scrollTop - clientHeight < 10) {
				VueEmit.$emit('scrolltolower');
			}
			VueEmit.$emit('scroll', event);
		},
		// 回到顶部
		gotoTop() {
			this.$nextTick(function () {
				const container = document.querySelector('.main-content');
				container.scrollTop = 0;
			});
		},
	},
};
</script>
<style lang="scss">
.layout {
	height: 100%;
	.el-header {
		padding: 0;
	}
	.el-main {
		padding: 0;
		overflow: hidden;
	}
}
</style>
