<template>
	<dialogBox
		showBottomBtn
		appendToBody
		v-model="dialogVisible"
		class="ChangeGroupName"
		@confirm="confirmClick"
	>
		<div slot="header_title" style="display: flex; align-items: center">
			修改群名称
		</div>
		<div class="setRow">
			<div class="setRow_title">群聊名称</div>
			<q-input placeholder="请输入备注名称" v-model="details.name"></q-input>
		</div>
	</dialogBox>
</template>
<script>
import dialogBox from '@/components/dialogBox.vue';
export default {
	components: { dialogBox },
	data() {
		return {
			dialogVisible: false,
			details: {},
		};
	},
	inject: ['getDetails', 'getImDetailsInfo'],
	methods: {
		open(id) {
			this.dialogVisible = true;
			this.getAppFriendsDetails(id);
		},

		// 详情
		async getAppFriendsDetails(id) {
			const res = await this.$http.imGroupDetails(id);
			this.details = res.data;
			console.log(res);
		},

		// 确定按钮
		async confirmClick(close) {
			const res = await this.$http.editImGroup({
				...this.details,
			});
			if (res.code == 200) {
				close();
				this.getImDetailsInfo();
				this.getDetails(this.details.id);
				this.$store.dispatch('getUserMessageList');
			}
		},
	},
};
</script>
<style lang="scss">
.ChangeGroupName {
	.setRow {
		margin-top: 23px;
		.setRow_title {
			color: #333333;
			font-size: 14px;
			font-weight: 500;
			margin-bottom: 8px;
		}
	}
}
</style>
