<template>
  <div :class="['header-container', isShow ? 'show-height' : 'hide-height']">
    <div class="header-wrap">
      <ul>
        <li v-for="(item, index) in navList"
            :key="index"
            :class="{'active': currentIndex === index}"
            @click="toPage(item, index)">{{ item.name }}</li>
        <li class="qr-icon"
            @mouseover="isShow = true"
            @mouseleave="isShow = false">
          <img src="../../assets/roleEnter/qr-icon.png" alt="qr">
        </li>
      </ul>
      <!-- 隐藏的二维码 -->
      <div :class="['qr-code', isShow ? 'show-qr' : 'hide-qr']">
        <img class="qr-code-img" src="../../assets/roleEnter/qr-code.png" alt="qrcode">
        <span>扫码下载</span>
        <span>SOHUGLOBA APP</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentIndex: 0,
      navList: [
        { id: 0, name: "平台介绍", path: "/roleEnter/roleIndex" },
        { id: 1, name: "平台资讯", path: "/roleEnter/platformInfo" },
        { id: 2, name: "帮助中心", path: "/roleEnter/helpCenter" },
        { id: 3, name: "立即入驻", path: "/roleEnter/immediateEnter" },
      ],
      isShow: false, // 是否显示二维码
    };
  },
  watch: {
    '$route.path': {
      handler(nUrl, oUrl) {
        if(nUrl.includes('/roleEnter')) {
          this.navList.map(item => {
            if(item.path === nUrl) {
              return this.currentIndex = item.id
            }
          })
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    toPage(v, i) {
      this.currentIndex = i
      this.$router.push(v.path);
    },
  }
};
</script>

<style lang="scss" scoped>
.header-container {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 999;
  background-color: white;
  padding: 50px 124px 24px;
  box-sizing: border-box;
  overflow: hidden;
  &.show-height {
    height: 251px;
    transition: all 0.3s ease-in;
  }
  &.hide-height {
    height: 122px;
    transition: all 0.3s ease-out;
  }
  .header-wrap {
    display: flex;
    flex-direction: column;
  }
  ul {
    display: flex;
    align-items: center;
    justify-content: right;
    padding-bottom: 30px;
    li {
      line-height: 1;
      font-size: 16px;
      color: #3D3D3D;
      cursor: pointer;
      margin-left: 64px;
      min-width: 72px;
      img {
        vertical-align: center;
      }
      &.active {
        font-size: 18px;
        font-weight: 600;
      }
      &.qr-icon {
        min-width: auto;
      }
    }
  }
  .qr-code {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: 5px;
    .qr-code-img {
      width: 80px;
      height: 80px;
      margin-bottom: 10px;
    }
    span {
      line-height: 17px;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.26);
    }
    &.show-qr {
      //display: flex;
      opacity: 1;
      transition: all 0.3s ease-in;
    }
    &.hide-qr {
      //display: none;
      opacity: 0;
      transition: all 0.3s ease-out;
    }
  }
}
</style>
