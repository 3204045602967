
import { get, postFile, postJson, deleteApi } from '@/utils/request';

export default {
	// 商单列表
	myBusyOrder(data) {
		return get(`/busy-order/app/busy/task/child/my/page`, data);
	},
    // 图文列表
	articlePage(data) {
		return get(`/admin/airecContent/article/page/center`, data);
	},
    // 视频列表
	videoPage(data) {
		return get(`/admin/airecContent/video/page/center`, data);
	},
	  // 问答列表
	  questionPage(data) {
		return get(`/admin/airecContent/question/page/center`, data);
	},
	// 短剧列表
	playletPage(data) {
		return get(`/admin/airecContent/playlet/page/center`, data);
	},
	// 套餐列表
	airecProductList(data) {
		return get(`/admin/airecProduct/list`, data);
	},
	// 获取字典数据
	getDicts(dictType) {
		return get(`/system/dict/data/type/${dictType}`);
	},
	// 创建订单
	createOrder(data) {
		return postJson(`/admin/airecOrder/createOrder`, data);
	},
	// 订单列表
	airecOrderList(data) {
		return get(`/admin/airecOrder/list`, data);
	},
	// 订单详情
	airecOrderDetail(id) {
		return get(`/admin/airecOrder/${id}`);
	}
}
