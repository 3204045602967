<template>
	<dialogBox appendToBody v-model="dialogVisible" class="GroupSettingsDialog">
		<div slot="header_title" style="display: flex; align-items: center">
			<el-image
				style="margin-right: 8px"
				:src="require('@/assets/images/chat/Calendar.png')"
			></el-image>
			群设置
		</div>
		<div class="userAvatarList">
			<div class="userData" v-for="item of userList" :key="item.id">
				<el-avatar :src="item.userAvatar"></el-avatar>
				<div class="userData_name line-1">{{ item.userName }}</div>
			</div>
			<div class="setGroupMember" @click="setGroupMember('邀请进群')">
				<i class="icon-sohu-jiahao"></i>
			</div>
			<div
				class="setGroupMember"
				@click="setGroupMember('移除群成员')"
				v-if="['leader', 'admin'].includes(groupRole)"
			>
				<i class="icon-sohu-jianhao"></i>
			</div>
		</div>
		<div class="viewMore" @click="viewMoreClick" v-if="userList.length >= 11">
			查看更多群成员 <i class="icon-sohu-xiayiyeqianjinchakangengduo"></i>
		</div>
		<div class="setList">
			<div
				v-for="item of setList"
				class="setCell"
				@click="rowSetClick(item)"
				v-if="
					item.roles.includes(groupRole) &&
					item.groupType.includes(details.groupType)
				"
			>
				<div class="setCell_label">
					{{ item.name }}
					<span class="setCell_label_submit" v-if="item.name == '进群审批'"
						>开启后，需群主或管理员审批后才能进群</span
					>
				</div>
				<div class="setCell_value">
					<div v-if="['群聊名称'].includes(item.name)" class="groupName line-1">
						{{ item.value }}
					</div>

					<div v-if="item.name == '群头像'">
						<FileUpload
							:fileTypes="['.jpg', '.jpeg', '.png']"
							:disabled="groupRole == 'user'"
							@success="fileUploadSuccess"
							><el-image class="logo" fit="cover" :src="details.logo"></el-image
						></FileUpload>
					</div>

					<div v-if="item.name == '群二维码'" class="qrCode">
						<i class="icon-sohu-erweima"></i>
					</div>

					<div v-if="item.name == '进群链接'">设置进群链接</div>

					<div v-if="item.name == '群公告' && !item.value">未设置</div>

					<div v-if="item.name == '进群审批'">
						<el-switch
							:disabled="groupRole == 'user'"
							v-model="item.value"
							active-color="#13ce66"
							@change="groupSwitchChange"
						></el-switch>
					</div>

					<i
						class="icon-sohu-xiayiyeqianjinchakangengduo"
						v-if="item.name != '进群审批'"
					></i>
				</div>
				<div
					v-if="['群公告'].includes(item.name) && item.value"
					class="groupNotice line-3"
					v-html="item.value"
				></div>
			</div>
			<div></div>
			<div slot="bottom_btn" class="bottom_btn">
				<el-button
					class="button confirm"
					@click="DissolveGroup"
					v-if="groupRole == 'leader'"
					>解散群</el-button
				>
				<el-button class="button confirm" @click="ExitGroup" v-else
					>退出群聊</el-button
				>
			</div>
		</div>

		<!-- 修改群名称 -->
		<ChangeGroupName ref="ChangeGroupNameRef"></ChangeGroupName>

		<!-- 群二维码 -->
		<GroupQRCode ref="GroupQRCodeRef"></GroupQRCode>

		<!-- 群关联-->
		<GroupAssociationDialog
			ref="GroupAssociationDialogRef"
		></GroupAssociationDialog>

		<!--	设置管理员	-->
		<set-admin ref="setAdminRef"></set-admin>

		<!--	群内禁言	-->
		<GroupProhibitionDialog
			ref="GroupProhibitionDialogRef"
		></GroupProhibitionDialog>

		<!-- 选择成员列表 -->
		<SelectMembersDialog ref="SelectMembersDialogRef"></SelectMembersDialog>

		<!-- 查看所有群成员 -->
		<GroupMembersDialog ref="GroupMembersDialogRef"></GroupMembersDialog>

		<!-- 设置渠道 -->
		<SetLinkDialog ref="SetLinkDialogRef"></SetLinkDialog>

		<!-- 群公告 -->
		<GroupAnnouncementDialog
			ref="GroupAnnouncementDialogRef"
			@success="GroupAnnouncementSuccess"
		></GroupAnnouncementDialog>
	</dialogBox>
</template>
<script>
import dialogBox from '@/components/dialogBox.vue';
import ChangeGroupName from './ChangeGroupName.vue';
import FileUpload from '@/components/FileUpload.vue';
import GroupQRCode from './GroupQRCode.vue';
import GroupAssociationDialog from './GroupAssociationDialog.vue';
import SetAdmin from './SetAdmin.vue';
import GroupProhibitionDialog from './GroupProhibitionDialog.vue';
import SelectMembersDialog from './SelectMembersDialog.vue';
import GroupMembersDialog from './GroupMembersDialog.vue';
import SetLinkDialog from './SetLinkDialog.vue';
import { sendWSPush } from '@/utils/websocket';
import GroupAnnouncementDialog from './GroupAnnouncementDialog.vue';
export default {
	components: {
		SetAdmin,
		GroupAssociationDialog,
		dialogBox,
		ChangeGroupName,
		FileUpload,
		GroupQRCode,
		GroupProhibitionDialog,
		SelectMembersDialog,
		GroupMembersDialog,
		SetLinkDialog,
		GroupAnnouncementDialog,
	},
	data() {
		return {
			dialogVisible: false,
			details: {},
			setList: [
				{
					name: '群聊名称',
					key: 'name',
					value: '',
					roles: ['user', 'leader', 'admin'],
					groupType: ['normal', 'subGroup', 'groupTask'],
				},
				{
					name: '群头像',
					roles: ['user', 'leader', 'admin'],
					groupType: ['normal', 'subGroup', 'groupTask'],
				},
				{
					name: '群二维码',
					roles: ['user', 'leader', 'admin'],
					groupType: ['normal', 'subGroup', 'groupTask'],
				},
				{
					name: '进群链接',
					roles: ['leader', 'admin'],
					groupType: ['normal', 'groupTask'],
				},
				{
					name: '群公告',
					key: 'groupNotice',
					value: '',
					roles: ['user', 'leader', 'admin'],
					groupType: ['normal', 'groupTask'],
				},
				{
					name: '群关联',
					roles: ['leader', 'admin'],
					groupType: ['normal'],
				},
				{
					name: '进群审批',
					key: 'needConfirm',
					value: false,
					roles: ['leader', 'admin'],
					groupType: ['normal', 'subGroup', 'groupTask'],
				},
				{
					name: '设置管理员',
					roles: ['leader'],
					groupType: ['normal', 'subGroup'],
				},
				{
					name: '群内禁言',
					roles: ['leader', 'admin'],
					groupType: ['normal', 'subGroup', 'groupTask'],
				},
			],
			userList: [],
			inviteCalculate: '',
			currentUser: {},
		};
	},
	provide() {
		return {
			getDetails: this.open,
		};
	},
	inject: {
		imGroupSubList: { value: 'imGroupSubList', default: null },
	},
	computed: {
		// 当前群角色
		groupRole() {
			let role = 'user';
			// 是否是群主
			if (this.$store.state.userInfo.id == this.details.userId) {
				role = 'leader';
			}
			let isGroupAdmin = this.userList.some((item) => {
				if (item.userId == this.$store.state.userInfo.id) {
					this.currentUser = item;
				}
				return (
					item.permissionType == 'group_admin' &&
					item.userId == this.$store.state.userInfo.id
				);
			});
			// 是否是管理员
			if (isGroupAdmin) {
				role = 'admin';
			}
			return role;
		},
	},
	methods: {
		async open(id) {
			this.dialogVisible = true;
			const res = await this.$http.imGroupDetails(id);
			this.details = res.data;
			if (this.details.pid) {
				this.details.groupType = 'subGroup';
			}
			this.setList.forEach((item) => {
				item.key && (item.value = res.data[item.key]);
			});
			console.log(this.setLis);
			this.getUserList();
			const res1 = await this.$http.imGroupGencode(id);
			this.inviteCalculate = res1.data.inviteCalculate;
		},

		// 查看更多群成员按钮
		viewMoreClick() {
			console.log('查看更多');
			this.$refs.GroupMembersDialogRef.open(
				this.details,
				['leader'].includes(this.groupRole),
				this.userList,
			);
		},

		// 群设置
		rowSetClick(val) {
			// 需要管理权限的设置
			if (['leader', 'admin'].includes(this.groupRole)) {
				switch (val.name) {
					case '群聊名称':
						this.$refs.ChangeGroupNameRef.open(this.details.id);
						break;
					case '群关联':
						this.$refs.GroupAssociationDialogRef.open(this.details.id);
						break;
					case '设置管理员':
						this.$refs.setAdminRef.open(this.details.id);
						break;
					case '群内禁言':
						this.$refs.GroupProhibitionDialogRef.open(this.details.id);
						break;
					case '进群链接':
						this.$refs.SetLinkDialogRef.open(
							this.details,
							this.inviteCalculate,
						);
						break;
				}
			}
			switch (val.name) {
				case '群二维码':
					this.$refs.GroupQRCodeRef.open(this.details.id);
					break;
				case '群公告':
					if (this.details.groupNotice || this.groupRole != 'user') {
						this.$refs.GroupAnnouncementDialogRef.open(
							this.details.id,
							this.groupRole,
						);
					}
					break;
			}
		},

		// 选择头像
		async fileUploadSuccess(file) {
			console.log(file.url);
			this.details.logo = file.url;
			const res = await this.$http.editImGroup({
				...this.details,
			});
			if (res.code == 200) {
				this.$message.success('修改成功');
				this.$store.dispatch('getUserMessageList');
			}
		},

		// 	切换进群审核
		async groupSwitchChange(val) {
			const res = await this.$http.imGroupSwitch(this.details.id);
			if (res.code == 200) {
				this.$message.success('状态修改成功');
			}
		},

		// 获取群成员列表
		async getUserList() {
			const res = await this.$http.imGroupUserAll({
				groupId: this.details.id,
			});
			this.userList = res.data;
		},

		// 设置群成员
		setGroupMember(title) {
			this.$refs.SelectMembersDialogRef.open({
				title: title,
				id: this.details.id,
				pid: this.details.pid,
			});
		},

		// 解散群按钮
		DissolveGroup() {
			this.$confirmDialog({
				content: '是否解散群？',
				beforeClose: async (state, close) => {
					if (state == 'confirm') {
						const res = await this.$http.imGroupDismiss(this.details.id);
						if (res.code == 200) {
							this.dialogVisible = false;
							this.$message.success('解散成功');
							close();
							this.$store.dispatch('getUserMessageList');
							sendWSPush({
								localId: this.$util.getUuid(),
								receiverId: this.details.id,
								sessionType: 'group',
								messageType: 'command',
								commandType: 'ungroup',
								content: '解散了群聊',
							});
						}
					}
				},
			});
		},

		// 退出群聊
		ExitGroup() {
			this.$confirmDialog({
				content: '是否退出群？',
				beforeClose: async (state, close) => {
					if (state == 'confirm') {
						const res = await this.$http.imGroupUserRemoveGroup(
							this.details.id,
						);
						if (res.code == 200) {
							this.dialogVisible = false;
							this.$message.success('退出成功');
							this.$store.dispatch('getUserMessageList');
						}
						close();
					}
				},
			});
		},

		// 群公告修改成功
		GroupAnnouncementSuccess() {
			this.$message.success('修改成功');
			sendWSPush({
				localId: this.$util.getUuid(),
				receiverId: this.details.id,
				sessionType: 'group',
				messageType: 'command',
				commandType: 'groupNotice',
				content: `${this.currentUser.userName}修改了群公告`,
			});
			//群公告修改成功消息
			sendWSPush({
				localId: this.$util.getUuid(),
				receiverId: this.details.id,
				sessionType: 'group',
				messageType: 'text',
				atIds: 0,
				content: `【群公告】\n${this.$refs.GroupAnnouncementDialogRef.details.groupNotice}\n@所有人`,
			});
			this.dialogVisible = false;
		},
	},
};
</script>
<style lang="scss">
.GroupSettingsDialog {
	.userAvatarList {
		display: grid;
		grid-template-columns: repeat(6, 1fr);
		grid-column-gap: 14px;
		grid-row-gap: 12px;
		margin-bottom: 16px;
		margin-top: 26px;
		max-height: 172px;
		overflow: hidden;

		.userData {
			text-align: center;
			.el-avatar {
				width: 60px;
				height: 60px;
				margin-bottom: 4px;
			}
			.userData_name {
				color: #787878;
				font-size: 14px;
			}
		}
		.setGroupMember {
			border: 1.36px dashed #b2b2b2;
			width: 60px;
			height: 60px;
			border-radius: 30px;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			i {
				font-size: 24px;
				color: #b2b2b2;
			}
			&:hover {
				opacity: 0.8;
			}
		}
	}
	.viewMore {
		margin: 20px 0;
		color: rgba(0, 0, 0, 0.6);
		text-align: center;
		line-height: 20px;
		font-size: 14px;
		cursor: pointer;
		&:hover {
			opacity: 0.8;
		}
	}
	.setList {
		.setCell {
			display: flex;
			align-items: center;
			justify-content: space-between;
			flex-wrap: wrap;
			border-top: 1px solid #e7e7e7;
			padding: 16px 0;
			cursor: pointer;
			&:last-child {
				border-bottom: 1px solid #e7e7e7;
			}
			&:hover {
				opacity: 0.8;
			}
			.setCell_label {
				color: #333333;
				font-size: 14px;
				line-height: 22px;
				.setCell_label_submit {
					color: rgba(0, 0, 0, 0.26);
					font-size: 12px;
					margin-left: 10px;
				}
			}
			.setCell_value {
				color: #999999;
				font-size: 14px;
				display: flex;
				align-items: center;
				position: relative;
				.groupName {
					max-width: 300px;
				}

				.logo {
					width: 30px;
					height: 30px;
					position: absolute;
					right: 18px;
					top: -8px;
				}
				.qrCode {
					i {
						font-size: 20px;
						color: rgba(0, 0, 0, 0.6);
					}
				}
			}
			.groupNotice {
				font-size: 13px;
				color: #a8acb0;
				line-height: 18px;
				margin-top: 8px;
				padding-right: 18px;
				white-space: pre-wrap;
				flex-basis: 100%;
			}
		}
	}
}
</style>
