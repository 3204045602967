import { get, postFile, postJson } from '@/utils/request';
import user from './apis/user';
import chat from './apis/chat';
import content from './apis/content';
import login from './apis/login';
import shortPlay from './apis/shortPlay';
import makeMoney from './apis/makeMoney';
import entry from './apis/entry';
import flow from './apis/flow';
import foxCoin from './apis/foxCoin';
import aiRec from '@/utils/apis/aiRec';
import aiChat from '@/utils/apis/aiChat';

export default {
	...user,
	...chat,
	...content,
	...login,
	...shortPlay,
	...makeMoney,
	...entry,
	...aiRec,
	...aiChat,
	...flow,
	...foxCoin,
	// 文件上传
	uploadFile(file) {
		let formData = new FormData();
		formData.append('file', file);
		return postFile('/resource/oss/upload', formData);
	},

	// 获取站点列表
	getSiteList(data) {
		return get('/app/api/site/getSiteTree', data);
	},

	//查询站点详情
	getSiteDetails(data) {
		return get(`/app/api/site/list`, data);
	},

	// 查询站点广告主体列表
	advertiseList(data) {
		return get(`/app/api/advertise/list`, data);
	},

	//认证
	getPayAccount(data) {
		return get(`/pay/account/account`, data);
	},

	//银行卡
	payAccountBankBank(data) {
		return get(`/pay/accountBank/bank`, data);
	},

	//获取当前位置
	getLocationDetail(data) {
		return get(`/app/api/site/getLocationDetail`, data);
	},

	// 获取地区列表
	areaCodeList(data) {
		return get(`/system/areaCode/list`, data);
	},

	// 支付接口
	payment(data) {
		return get(`/pay/pay/pc/payment`, data);
	},
	//根据IP获取站点信息
	getSiteByIp(data) {
		return get(`/app/api/site/getSiteByIp`, data);
	},
	
};
