<template>
	<div class="q-aside">
		<div class="navList">
			<div
				@click="navActiveClick(item)"
				:class="[{ active: navActive == item.path }, 'navList_item']"
				v-for="(item, i) of navList"
			>
				<div class="iconBox">
					<i :class="navActive == item.path ? item.active_icon : item.icon"></i>
				</div>
				{{ item.name }}
			</div>
		</div>
		<div class="line"></div>
		<div class="navList">
			<div
				@click="navActiveClick(item)"
				:class="[{ active: navActive == item.path }, 'navList_item']"
				v-for="(item, i) of contentList"
			>
				<div class="iconBox">
					<i :class="navActive == item.path ? item.active_icon : item.icon"></i>
				</div>
				{{ item.name }}
			</div>
		</div>
		<div class="bottomSet">
			<div class="line"></div>
			<div
				:class="['more', { active: moreShow }]"
				@click="moreShow = !moreShow"
			>
				<i class="icon-sohu-shezhi"></i>更多
			</div>
		</div>
		<!-- 更多弹框 -->
		<div class="moreBox" @click="moreShow = false" v-if="moreShow">
			<div class="mainBox" @click.stop="">
				<div class="moreList">
					<div
						:class="['item', { active: moreValue === v.value }]"
						@click.stop="moreClick(v)"
						v-for="v of moreList"
						:key="v.value"
					>
						{{ v.title }}
					</div>
				</div>
				<!-- 关于狐少少 -->
				<div class="soHuGlobalIntroduceBox" v-if="moreValue === 'sohuGlobal'">
					<img
						class="icon"
						src="https://sohugloba.oss-cn-beijing.aliyuncs.com/2024/04/13/c32fdc50b50a4ade840c6b91d6fdef31_84x78.png"
					/>
					<div class="title">
						狐少少<span>|</span>伟吉鑫（湖北）文化传媒有限公司
					</div>
					<div class="relationBox">
						<div class="row">
							<i class="icon icon-sohu-dianhua"></i>027-88872177
						</div>
						<div class="row">
							<img
								src="https://sohugloba.oss-cn-beijing.aliyuncs.com/2024/05/21/f945d908a7d34ff2a977b01420662b01_36x36.png"
							/>
							humaimai1@hotmail.com
						</div>
						<div class="row">
							<i class="icon icon-sohu-dingwei"></i
							>武汉市武昌区徐家棚街道沙湖路11号桃源国际商业中心901
						</div>
					</div>
					<div class="AQ">
						<div class="site">
							<img
								class="img"
								src="https://sohugloba.oss-cn-beijing.aliyuncs.com/2024/04/13/3767ea50b070404d8449be74bddcee49_36x36.png"
							/>ICP：鄂ICP备2024045196号-3
						</div>
						<div class="site" @click="previewImg('网络文化经营许可证')">
							网络文化经营许可证：鄂网文（2024）1642-147号
						</div>
						<div
							class="site"
							@click="previewImg('中华人民共和国增值电信业务经营许可证')"
						>
							中华人民共和国增值电信业务经营许可证：鄂B2-20240481
						</div>
						<!-- <div class="num">
							<img
								class="img"
								src="https://sohugloba.oss-cn-beijing.aliyuncs.com/2024/04/13/3767ea50b070404d8449be74bddcee49_36x36.png"
							/>鄂公网安备：42010602004857
						</div> -->
					</div>
				</div>
				<!-- 帮助中心 -->
				<div class="helpBox" v-if="moreValue === 'helpCenter'">
					<div class="helpList">
						<div
							class="item"
							@click.stop="skipClick(v)"
							v-for="v of helpList"
							:key="v.value"
						>
							{{ v.title }}
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 反馈与建议弹框 -->
		<div class="suggestBox" v-if="moreValue === 'feedback'">
			<div class="mainBox">
				<div class="heard">
					<div class="text">反馈与建议</div>
					<i class="icon icon-sohu-quxiao" @click="cancelClick"></i>
				</div>
				<div class="textareaBox">
					<div class="text">
						<span>*</span>
						<div>反馈与建议</div>
					</div>
					<el-input
						v-model="feedbackMsg"
						type="textarea"
						maxlength="200"
						show-word-limit
						placeholder="请简要说明您遇到的问题或对产品的建议，我们将为您不断改进 ~"
					></el-input>
				</div>
				<div class="uploadImgBox">
					<div class="text">
						添加图片（{{ (imageUrl && imageUrl.split(',').length) || 0 }}/5）
					</div>
					<upload-image
						v-model="imageUrl"
						class="upload"
						accept="image/jpeg,image/jpg,image/png"
						:limit="5"
					></upload-image>
				</div>

				<div class="bottomBtn">
					<div class="submitBtn" @click="submitBtnClick">提交</div>
				</div>
			</div>
		</div>

		<el-image
			:z-index="5000"
			style="display: none"
			:preview-src-list="[previewSrc]"
			ref="imgPreviewRef"
		></el-image>
	</div>
</template>
<script>
import UploadImage from '@/components/UploadImage.vue';
export default {
	components: { UploadImage },
	data() {
		return {
			navList: [
				{
					name: '首页',
					icon: 'icon-sohu-shouye',
					active_icon: 'icon-sohu-shouyexuanzhong01',
					path: '/home',
				},
				{
					name: '短剧',
					icon: 'icon-sohu-duanju',
					active_icon: 'icon-sohu-duanju-xuanzhong',
					path: '/shortPlay',
				},
				{
					name: '赚钱',
					icon: 'icon-sohu-zhuanqian',
					active_icon: 'icon-sohu-zhuanqian-xuanzhong',
					path: '/makeMoney',
				},
				{
					name: '我的',
					icon: 'icon-sohu-wode',
					active_icon: 'icon-sohu-wodexuanzhong',
					path: '/my',
				},
			],
			navActive: this.$route.path,
			contentList: [
				{
					name: '视频',
					icon: 'icon-sohu-shipin',
					active_icon: 'icon-sohu-shipin-xuanzhong',
					path: '/content/videoList',
				},
				{
					name: '图文',
					icon: 'icon-sohu-tuwen',
					active_icon: 'icon-sohu-tuwen-xuanzhong',
					path: '/content/imageTextList',
				},
				{
					name: '问答',
					icon: 'icon-sohu-wenda',
					active_icon: 'icon-sohu-wenda-xuanzhong',
					path: '/content/questionList',
				},
			],
			moreShow: false, //更多弹框
			moreValue: '',
			moreList: [
				{
					title: '关于狐少少',
					value: 'sohuGlobal',
				},
				{
					title: '用户协议',
					value: 'userAgreement',
					url: 'https://world.sohuglobal.com/agreementOfUser.html',
				},
				{
					title: '隐私协议',
					value: 'privacyAgreement',
					url: 'https://world.sohuglobal.com/agreementOfPrivacy.html',
				},
				{
					title: '帮助中心',
					value: 'helpCenter',
				},
				{
					title: '反馈与建议',
					value: 'feedback',
					login: true,
				},
			],
			helpList: [
				{
					title: '平台商家入驻须知',
					url: 'https://world.sohuglobal.com/入驻协议.html',
				},
				{
					title: '任务方发布须知',
					url: 'https://world.sohuglobal.com/任务发布.html',
				},
				{
					title: '任务接单须知',
					url: 'https://world.sohuglobal.com/任务接单.html',
				},
				{
					title: '任务交付须知',
					url: 'https://world.sohuglobal.com/任务交付.html',
				},
				{
					title: '账号注销须知',
					url: 'https://world.sohuglobal.com/协议/账号注销.html',
				},
			],
			imageUrl: '',
			feedbackMsg: '',
			previewSrc: '',
		};
	},
	watch: {
		$route(to, from) {
			this.navActive = to.path;
		},
	},
	methods: {
		// 图片预览
		previewImg(val) {
			switch (val) {
				case '网络文化经营许可证':
					this.previewSrc = require('@/assets/images/networkCulture.jpeg');
					break;
				case '中华人民共和国增值电信业务经营许可证':
					this.previewSrc = require('@/assets/images/Value-AddedTelecommunications.jpg');
					break;
			}
			this.$refs.imgPreviewRef.clickHandler();
			this.moreShow = false;
		},

		// 点击路由跳转
		navActiveClick(val) {
			if (val.name === '我的') {
				this.$login().then((res) => {
					this.navActive = val.path;
					this.$router.push(val.path);
				});
			} else {
				this.navActive = val.path;
				this.$router.push(val.path);
			}
		},
		//更多
		moreClick(val) {
			if (val.login) {
				this.$login().then((res) => {
					this.moreValue = val.value;
				});
			} else if (val.url) {
				this.moreValue = val.value;
				window.open(val.url);
			} else {
				this.moreValue = val.value;
			}
			if (val.value === 'feedback') {
				this.moreShow = false;
			}
		},
		//帮助中心跳转
		skipClick(val) {
			window.open(val.url);
		},
		//取消
		cancelClick() {
			this.moreValue = '';
			this.feedbackMsg = '';
			this.imageUrl = '';
		},
		//反馈建议
		async submitBtnClick() {
			let res = await this.$http.getFeedbackInfo({
				feedbackMsg: this.feedbackMsg,
				userId: this.$store.state.userInfo.id,
				feedbackImg: this.imageUrl,
			});
			if (res.code === 200) {
				this.$message({
					message: '提交成功',
					type: 'success',
				});
				this.cancelClick();
			}
		},
	},
};
</script>
<style lang="scss">
.q-aside {
	background: #fcfcff;
	height: 100%;
	padding: 30px 15px 0;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	position: relative;
	.navList {
		display: flex;
		flex-direction: column;
		.navList_item {
			width: 163px;
			height: 48px;
			border-radius: 24px 0 0 24px;
			line-height: 48px;
			padding-left: 6px;
			box-sizing: border-box;
			font-size: 16px;
			color: #333333;
			display: flex;
			align-items: center;
			cursor: pointer;
			.iconBox {
				background: #fcfcff;
				width: 36px;
				height: 36px;
				border-radius: 50%;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-right: 7px;
				i {
					font-size: 28px;
				}
			}
			&.active {
				font-weight: 500;
				color: #fff1e9;
				background: linear-gradient(
					90deg,
					$theme-color 50%,
					$collect-color 111%
				);
				i {
					background: linear-gradient(180deg, #ffcf2f 0%, #f15b14 100%);
					-webkit-background-clip: text;
					-webkit-text-fill-color: transparent;
				}
			}
			&:hover:not(.active) {
				background: #f5f5f9;
			}
		}
	}
	.line {
		width: 150px;
		height: 1px;
		background-color: #f5f5f5;
		margin-top: 39px;
		margin-bottom: 20px;
	}
	.bottomSet {
		// position: absolute;
		// bottom: 190px;
		// left: 15px;
		cursor: pointer;
		div {
			color: #333333;
			font-size: 16px;
			line-height: 24px;
			margin-bottom: 9px;
			display: flex;
			align-items: center;
		}

		.more {
			width: 163px;
			height: 48px;
			border-radius: 24px 0 0 24px;
			line-height: 48px;
			padding-left: 6px;
			box-sizing: border-box;
			font-size: 16px;
			color: #333333;
			display: flex;
			align-items: center;
			cursor: pointer;
			i {
				font-size: 24px;
				margin-right: 8px;
				width: 36px;
				height: 36px;
				border-radius: 50%;
				display: flex;
				align-items: center;
				justify-content: center;
			}
			&.active {
				background: #f5f5f9;
				i {
					background: #fcfcff;
				}
			}

			&:hover {
				background: #f5f5f9;
				i {
					background: #fcfcff;
				}
			}
		}
	}
	.moreBox {
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		z-index: 999999 !important;
		.mainBox {
			display: flex;
			position: absolute;
			bottom: 104px;
			left: 124px;
			width: max-content;
			z-index: 999999999 !important;

			background: #ffffff;
			border-radius: 12px;
			box-shadow: 0px 3px 14px 2px rgba(0, 0, 0, 0.05),
				0px 8px 10px 1px rgba(0, 0, 0, 0.06),
				0px 5px 5px -3px rgba(0, 0, 0, 0.04);
			.moreList {
				padding: 12px 24px;
				.item {
					width: 104px;
					font-size: 14px;
					font-weight: normal;
					line-height: 22px;
					padding: 3px 8px;
					margin-bottom: 24px;
					cursor: pointer;
					&.active {
						color: $theme-color;
					}
					&:hover {
						color: $theme-color;
					}
					&:nth-child(0n + 5) {
						margin-bottom: 0;
					}
				}
			}
			.soHuGlobalIntroduceBox {
				min-height: 260px;
				width: 509px;
				border-radius: 0px 12px 12px 0px;
				background-color: #fff;
				border-left: 1px solid #e7e7e7;
				padding: 18px 20px 20px 20px;
				box-sizing: border-box;
				.icon {
					width: 28px;
					height: 26px;
					margin-bottom: 10px;
				}
				.title {
					color: rgba(0, 0, 0, 0.9);
					font-size: 14px;
					font-weight: 500;
					line-height: 22px;
					margin-bottom: 16px;
					span {
						margin: 0 12px;
						color: #dcdcdc;
					}
				}
				.relationBox {
					background: #f9f9fa;
					width: 469px;
					height: 106px;
					border-radius: 4px;
					margin-bottom: 20px;
					padding-top: 19px;
					padding-left: 17px;
					box-sizing: border-box;
					.row {
						font-size: 12px;
						font-weight: normal;
						line-height: 24px;
						color: #6f6f6f;
						.icon {
							margin-right: 8px;
							color: #6f6f6f;
						}
						img {
							width: 12px;
							height: 12px;
							margin-right: 6px;
						}
					}
				}
				.AQ {
					display: flex;
					flex-wrap: wrap;
					.site {
						font-size: 12px;
						font-weight: normal;
						color: #6f6f6f;
						line-height: 24px;
						margin-right: 20px;
					}
					.num {
						font-size: 12px;
						font-weight: normal;
						color: #6f6f6f;
						line-height: 24px;
					}
					img {
						margin-right: 8px;
						width: 12px;
						height: 12px;
					}
				}
			}
			.helpBox {
				box-sizing: border-box;
				width: 196px;
				height: 260px;
				padding: 12px 24px;
				border-radius: 0px 12px 12px 0px;
				border-left: 1px solid #e7e7e7;
				.helpList {
					.item {
						width: 148px;
						box-sizing: border-box;
						font-size: 14px;
						font-weight: normal;
						line-height: 22px;
						padding: 3px 8px;
						margin-bottom: 24px;
						cursor: pointer;
						&:hover {
							color: $theme-color;
						}
						&:nth-child(0n + 5) {
							margin-bottom: 0;
						}
					}
				}
			}
		}
	}
	.suggestBox {
		position: fixed;
		left: 0;
		top: 0;
		width: 100vw;
		height: 100vh;
		z-index: 9999;
		display: flex;
		align-items: center;
		justify-content: center;
		background: rgba(0, 0, 0, 0.3);
		div {
			box-sizing: border-box;
		}
		.mainBox {
			width: 680px;
			height: 539px;
			border-radius: 20px;
			background: #ffffff;
			box-sizing: border-box;
			z-index: 99999;
			.heard {
				display: flex;
				justify-content: space-between;
				padding: 20px;
				margin-bottom: 1px solid #f5f5f5;
				.text {
					font-size: 18px;
					font-weight: 600;
					line-height: 24px;
					color: #3d3d3d;
				}
				.icon {
					font-size: 16px;
					margin: 4px;
					cursor: pointer;
				}
			}
			.textareaBox {
				display: flex;
				padding: 20px;
				.text {
					margin-right: 26px;
					display: flex;
					span {
						color: #f53f3f;
						margin-right: 5px;
						line-height: 20px;
					}
					div {
						color: #3d3d3d;
						font-size: 14px;
						font-weight: 500;
						line-height: normal;
						width: 70px;
					}
				}
				.el-textarea {
					width: 512px;
					height: 172px;
					background: #f7f7f7;
					border-radius: 16px;
					padding-top: 5px;
					.el-textarea__inner {
						width: 512px;
						height: 172px;
						background: #f7f7f7;
						border: none;
						border-radius: 16px;
					}
				}
			}
			.uploadImgBox {
				.text {
					font-size: 14px;
					font-weight: 500;
					line-height: normal;
					color: #4e5969;
					margin-bottom: 23px;
					margin-left: 20px;
					span {
						color: #f53f3f;
						margin-right: 5px;
					}
				}
				.upload {
					margin-left: 33px;
					display: flex;
					.upload-image-box {
						width: 104px;
						height: 104px;
						border-radius: 4px;
						margin-right: 5px;
					}
					.image-item {
						width: 104px;
						height: 104px;
						border-radius: 4px;
						margin-top: 0;
						margin-right: 5px;
					}
					.preview-images {
						width: auto;
					}
				}
			}
			.bottomBtn {
				display: flex;
				justify-content: flex-end;
				border-top: 1px solid #f5f5f5;
				padding: 20px 40px;
				margin-top: 40px;
				.submitBtn {
					width: 88px;
					height: 36px;
					border-radius: 8px;
					font-size: 14px;
					font-weight: normal;
					line-height: 22px;
					display: flex;
					align-items: center;
					justify-content: center;
					background: #ff6c27;
					color: #fff;
					cursor: pointer;
					&:hover {
						background-color: #ff9528;
					}
				}
			}
		}
	}
}
</style>
