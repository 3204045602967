<template>
	<div class="FileUpload">
		<div class="upload-head">
			<div
				class="fileUploadBtn"
				@click="fileUploadClick"
				:class="{ isDisabled: disabled }"
			>
				<slot>
					<el-button
						size="small"
						:disabled="disabled"
						type="primary"
						:loading="buttonLoading"
						icon="el-icon-upload2"
					>{{ buttonLoading ? '上传中' : '附件上传' }}</el-button
					></slot
				>
			</div>

			<!-- 上传提示 -->
			<div class="el-upload__tip" v-if="showTip && !disabled">
				请上传
				<template v-if="fileMaxSize">
					大小不超过 <b style="color: #EF8052">{{ fileMaxSize }}MB</b>
				</template>
				<template v-if="fileTypes">
					格式为 <b style="color: #EF8052">{{ fileTypes.join('/') }}</b>
				</template>
				的文件
			</div>
		</div>


		<!-- 文件列表 -->
		<template v-if="fileListShow">
			<div
				class="file-item"
				v-for="(file,index) in fileList"
				:key="file"
			>
				<div class="file-name">
					<el-link :href="file" :underline="false" target="_blank">{{ file }}</el-link>
				</div>
				<i class="iconfont icon-sohu-shanchu"  @click="handleDelete(index)" v-if="!disabled"></i>
			</div>
		</template>

		<p class="icon-tip" v-if="iconTip !== ''">
			<i class="iconfont icon-sohu-tishi"></i>
			<span>{{ iconTip }}</span>
		</p>

		<input
			ref="inputFileRef"
			:accept="fileTypes.join()"
			type="file"
			hidden
			@change="fileChange"
		/>
	</div>
</template>
<script>
export default {
	props: {
		value: String,
		fileTypes: {
			type: Array,
			default: () => [],
		},
		fileMaxSize: {
			type: Number,
			default: 200,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		showTip: {
			type: Boolean,
			default: false,
		},
		fileListShow: {
			type: Boolean,
			default: false,
		},
		iconTip:{
			type:String,
			default:""
		}
	},
	data() {
		return {
			file: '',
			buttonLoading: false,
		};
	},
	watch: {
		value: {
			handler(val) {
				console.log(val);
				if (val) {
					this.fileList = val.split(',');
				} else {
					this.fileList = [];
				}
			},
			depp: true,
			immediate: true,
		},
	},
	methods: {
		// 选择文件按钮
		fileUploadClick() {
			if (!this.buttonLoading && !this.disabled) {
				this.$refs.inputFileRef.click();
			}
		},

		async fileChange(e) {
			this.file = '';
			let file = e.target.files[0];
			let fileName = e.target.files[0].name;
			let fileMaxSize = 1024 * 1024 * this.fileMaxSize;
			let fileType = this.fileTypes.join('|');
			let fileNameReg = new RegExp(`${fileType}$`); //设置文件格式
			if (fileNameReg.test(fileName.toLowerCase())) {
				if (file.size > fileMaxSize) {
					this.$message.error(`文件大小不能超过${this.fileMaxSize}M`);
				} else {
					this.file = file;
				}
			} else {
				this.$message.error('文件格式错误');
			}
			if (this.file) {
				try {
					this.buttonLoading = true;
					const res = await this.$http.uploadFile(this.file);
					this.$emit('success', res.data, this.file);
					this.buttonLoading = false;
					this.fileList.push(res.data.url);
					this.$emit('input', this.fileList.join());
				} catch (error) {
					this.buttonLoading = false;
				}
			}
			this.$refs.inputFileRef.value = '';
		},

		// 删除文件
		handleDelete(index) {
			this.fileList.splice(index, 1);
			this.$emit('input', this.fileList.join());
		},
	},
};
</script>
<style lang="scss">
.FileUpload {
	.fileUploadBtn {
		&.isDisabled {
			opacity: 0.4;
		}
	}
	.el-upload__tip {
		line-height: 30px;
		margin: 0;
		color: #999999;
	}
	.file-item{
		display: flex;
		align-items: center;
		margin-top: 16px;
		.file-name{
      width: 320px;
      height: 26px;
      color: #333333;
      background-color: #F5F7FA;
      padding: 0 12px;
      line-height: 26px;
			.el-link{
				width: 100%;
        vertical-align: bottom;
				span {
          white-space: nowrap;
          overflow: hidden; /* 隐藏超出容器的内容 */
          text-overflow: ellipsis; /* 当文本溢出时显示省略号 */
				}
			}

			border-radius: 4px;
		}
    i{
			margin-left: 24px;
		}
	}
	.icon-tip{
    color: #FF6C27;
		display: flex;
		align-items: center;
		margin-top: 12px;
		i {
			font-size: 13px;
		}
		span{
			font-size: 12px;
			margin-left: 4px;
			line-height: 16px;
		}
	}
}
</style>
