import { get, postFile, postJson, deleteApi } from '@/utils/request';

export default {
	// 充值列表
	rechargeList(data) {
		return get(`/app/api/common/coin/recharge/list`, data);
	},
	//获取用户虚拟币余额
	rechargeVirtual(data) {
		return get(`/app/virtual`, data);
	}
};
