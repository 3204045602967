<template>
  <div class="footer-container">
    <div class="footer-wrap">
      <div class="content">
        <h1>狐少少</h1>
        <p>地址：武汉市武昌区沙湖路11号桃源国际商业中心9F</p>
        <p>联系方式：027-88872177</p>
        <p>邮箱：humaimai1@hotmail.com</p>
        <div class="icp-box">
          <div class="icp-item">
            <span class="icp-label">ICP：</span>
            <a target="_blank" class="icp-a" :href="icp1">
              <img class="icp-logo" src="../../assets/image/ICP-logo.png" alt="" />
              <span>鄂ICP备2021020360号-3</span>
            </a>
            <a target="_blank" class="icp-a" :href="icp2">
              <img class="icp-logo" src="../../assets/image/ICP-logo.png" alt="" />
              <span>鄂公网安备 42010602004857号</span>
            </a>
          </div>
        </div>
      </div>
      <div class="qrcode">
        <img src="../../assets/image/qrcode.png" alt="qrcode" />
        <span>微信小程序</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RoleFooter",
  data() {
    return {
      icp1: "https://beian.miit.gov.cn/?spm=5176.168087.J_9220772140.52.913757a3ihxwIg#/Integrated/index",
      icp2: "https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=42010602004857",
    };
  },
  methods: {
  }
};
</script>

<style scoped lang="scss">
.footer-container {
  background-color: #1c1f25;
  .footer-wrap {
    padding: 42px 60px 22px 120px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    box-sizing: border-box;
  }
}
.content {
  display: flex;
  flex-direction: column;
  h1 {
    font-size: 18px;
    line-height: 22px;
    padding-bottom: 9px;
  }
  p {
    line-height: 22px;
    padding-top: 6px;
  }
}
.qrcode {
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    width: 120px;
    height: 120px;
  }
  span {
    text-align: center;
    line-height: 22px;
    padding-top: 18px;
  }
}
.icp-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 15px;
  .icp-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    .icp-label {
      line-height: 22px;
    }
    .icp-a {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: white;
      margin-right: 10px;
      .icp-logo {
        width: 20px;
        vertical-align: middle;
        margin-right: 5px;
      }
      &:hover {
        color: blue;
      }
    }
  }
}
</style>
