<template>
	<dialogBox appendToBody v-model="dialogVisible" class="friendsDialogVisible">
		<div slot="header_title" style="display: flex; align-items: center">
			<el-image
				style="margin-right: 8px"
				:src="require('@/assets/images/chat/Calendar.png')"
			></el-image>
			通讯录
		</div>
		<div class="contentBox">
			<div class="typeTabs">
				<div
					:class="[{ active: typeTabsActive == 1 }, 'typeTabs_item']"
					@click="friendsTypeClick(1)"
				>
					好友
				</div>
				<div
					:class="[{ active: typeTabsActive == 2 }, 'typeTabs_item']"
					@click="friendsTypeClick(2)"
				>
					群聊
				</div>
			</div>
			<q-input
				@search-click="searchClick"
				v-model="searchInput"
				placeholder="请输入要查找的内容"
				clearable
				showSearchIcon
				@clear="searchClick"
			></q-input>
			<div class="userList" v-infinite-scroll="userLoad">
				<template v-if="total">
					<AddressBook_row
						:type="typeTabsActive == 1 ? '好友' : '群聊'"
						v-for="item of list"
						:key="item.id"
						:item="item"
					></AddressBook_row>
				</template>
				<div class="userList_empty" v-else>
					暂无{{ typeTabsActive == 1 ? '好友' : '群聊' }}
				</div>
			</div>
		</div>
	</dialogBox>
</template>
<script>
import dialogBox from '@/components/dialogBox.vue';
import AddressBook_row from './AddressBook_row.vue';
export default {
	components: { dialogBox, AddressBook_row },
	data() {
		return {
			typeTabsActive: 1,
			list: [],
			pageNum: 1,
			total: 0,
			searchInput: '',
			dialogVisible: false,
			groupList: [],
		};
	},
	inject: ['chatUserItemClick'],
	provide() {
		return {
			close: this.close,
			getImGroupList: this.getImGroupList,
		};
	},
	methods: {
		open() {
			this.dialogVisible = true;
			this.pageNum = 1;
			if (this.typeTabsActive == 1) {
				this.getAppFriendsList();
			} else {
				this.getImGroupList();
			}
		},

		// 关闭
		close(val) {
			this.chatUserItemClick(val);
			this.dialogVisible = false;
		},

		// 好友弹窗滚动到底部
		userLoad() {
			if (this.total > this.list.length) {
				this.pageNum++;
				if (this.typeTabsActive == 1) {
					this.getAppFriendsList();
				} else {
					this.getImGroupList();
				}
			}
		},

		// 获取好友列表
		async getAppFriendsList() {
			const res = await this.$http.appFriendsList({
				alias: this.searchInput,
				pageSize: 10,
				pageNum: this.pageNum,
				applyState: 'pass',
			});
			if (this.pageNum == 1) {
				this.list = res.data;
			} else {
				this.list = this.list.concat(res.data);
			}
			this.total = res.total;
		},

		// 获取群聊列表
		async getImGroupList() {
			const res = await this.$http.imGroupJoinList();
			this.groupList = res.data;
			this.list = this.groupList;
		},

		// 切换类型
		friendsTypeClick(val) {
			this.typeTabsActive = val;
			this.pageNum = 1;
			if (this.typeTabsActive == 1) {
				this.getAppFriendsList();
			} else {
				this.getImGroupList();
			}
		},

		// 搜索按钮
		searchClick() {
			this.pageNum = 1;
			if (this.typeTabsActive == 1) {
				this.getAppFriendsList();
			} else {
				this.list = this.groupList.filter((item) => {
					return item.name.includes(this.searchInput);
				});
			}
		},
	},
};
</script>
<style lang="scss">
.friendsDialogVisible {
	.contentBox {
		.typeTabs {
			display: flex;
			align-items: center;
			border: 1.6px solid #f4f2f2;
			height: 36px;
			border-radius: 20px;
			overflow: hidden;
			line-height: 36px;
			margin: 24px 0 16px 0;
			.typeTabs_item {
				text-align: center;
				width: 50%;
				height: 100%;
				font-size: 14px;
				font-weight: 500;
				color: #999999;
				cursor: pointer;
				&:hover {
					opacity: 0.8;
				}
				&.active {
					color: #ffffff;
					background: $theme-color;
					border-radius: 20px;
				}
			}
		}
		.userList {
			height: 340px;
			overflow-y: auto;
			margin-top: 20px;

			.userList_empty {
				margin-top: 58px;
				text-align: center;
				color: #999999;
				font-size: 18px;
				font-weight: 500;
			}
		}
	}
}
</style>
