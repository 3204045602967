<template>
	<div class="InteractiveMessages_card">
		<div v-for="item of list" :key="item.id" class="card_row">
			<div class="leftUserInfo">
				<div class="userAvatar">
					<el-avatar :src="item.operatorUserAvatar"></el-avatar>
					<el-image class="typeIcon" :src="iconFilter(item.type)"></el-image>
				</div>
				<div class="userData">
					<div class="username">{{ item.operatorUserName }}</div>
					<div class="content">
						{{ item.content }}
					</div>
					<div class="time">{{ item.updateTime }}</div>
				</div>
			</div>
			<el-image
				:src="item.objCoverImage"
				class="rightImg"
			></el-image>
		</div>
	</div>
</template>
<script>
export default {
	props: ['list'],
	data() {
		return {};
	},
	methods: {
		// 图标过滤
		iconFilter(val) {
			switch (val) {
				case 'like':
					return require('@/assets/images/chat/like.png');
				case 'commentReceive':
					return require('@/assets/images/chat/comment.png');
				case 'commentSend':
					return require('@/assets/images/chat/comment.png');
				case 'collect':
					return require('@/assets/images/chat/collection.png');
			}
		},
	},
};
</script>
<style lang="scss">
.InteractiveMessages_card {
	background: #ffffff;
	border-radius: 8px;
	padding: 24px;
	box-sizing: border-box;
	display: grid;
	row-gap: 24px;
	.card_row {
		display: flex;
		align-items: center;
		justify-content: space-between;
		.leftUserInfo {
			flex: 1;
			display: flex;
			padding-right: 32px;
			.userAvatar {
				position: relative;
				width: 48px;
				height: 48px;
				margin-right: 10px;
				.el-avatar {
					width: 48px;
					height: 48px;
				}
				.typeIcon {
					position: absolute;
					right: 0;
					bottom: 0;
					width: 22px;
					height: 22px;
					transform: translate(4px, 4px);
				}
			}
			.userData {
				.username {
					font-size: 14px;
					font-weight: 500;
					line-height: 22px;
					color: #3d3d3d;
				}
				.content {
					color: #555555;
					font-size: 12px;
					line-height: 18px;
					margin: 2px 0;
				}
				.time {
					color: #bcbcbc;
					font-size: 10px;
					line-height: 16px;
				}
			}
		}
		.rightImg {
			border-radius: 4px;
			width: 42px;
			height: 56px;
		}
	}
}
</style>
