import Vue from 'vue';
import report from './index.vue';
const reportConstructor = Vue.extend(report);

let instance;
const initInstance = (obj) => {
	instance = new reportConstructor({
		el: document.createElement('div'),
		data() {
			return {
				...obj,
			};
		},
	});
};

const showReport = (obj) => {
	return new Promise((resolve, reject) => {
		initInstance(obj);
		document.body.appendChild(instance.$el);
		Vue.nextTick(() => {
			instance.reportDialogShow = true;
		});

		instance.callback = (action) => {
			instance.$el.parentNode.removeChild(instance.$el);
			if (action === 'confirm') {
				resolve();
			} else if (action === 'cancel') {
				reject();
			}
		};
	});
};

Vue.prototype.$report = showReport;
