<template>
	<div class="download-dialog" v-show="value" @click="$emit('input',false)">
		<div class="content" @click.stop="$emit('input',true)">
			<i class="iconfont icon-sohu-quxiao" @click.stop="$emit('input',false)"></i>
			<div class="title">该消息不支持PC端打开，可通过狐少少App查看</div>
			<div class="qr-code"></div>
			<div class="tip">扫一扫下载狐少少App</div>
		</div>
	</div>
</template>

<script>
import QRCode from "qrcodejs2";
export default {
	name: "download_dialog",
	props:{
		value:{
			type: Boolean,
			default: false
		}
	},
	mounted() {
		this.qrCodeInit();
	},
	methods:{
		qrCodeInit(){
			let qrcodeDiv = document.querySelector(".qr-code");
			new QRCode(qrcodeDiv, {
				text: 'https://world.sohuglobal.com/#/contentPackages/downloadApp/downloadApp', // 用于生成二维码的文本
				width: 157, // 高度
				height: 157, // 宽度
				colorDark: '#000000', //前景色
				colorLight: '#ffffff', //后景色
				correctLevel: QRCode.CorrectLevel.H, //容错级别 QRCode.CorrectLevel.L QRCode.CorrectLevel.M QRCode.CorrectLevel.Q QRCode.CorrectLevel.H
			})
		}
	}
};
</script>

<style lang="scss" scoped>
.download-dialog {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.3);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    .content {
        width: 301px;
        height: 339px;
        background-color: #FFFFFF;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding: 40px 0;
        box-sizing: border-box;
        position: relative;
        .icon-sohu-quxiao {
            position: absolute;
            right: 17px;
            top: 14px;
            font-size: 12px;
        }
        .title {
            font-size: 16px;
            color: #3D3D3D;
            padding: 0 35px 0 36px;
            text-align: center;
            line-height: 24px;
        }
        .qr-code {
            width: 157px;
            height: 157px;
        }
        .tip {
            font-size: 16px;
            color: #3D3D3D;
            line-height: 24px;
        }
    }
}
</style>
