<template>
	<dialogBox
		appendToBody
		:showHeader="false"
		v-model="reportDialogShow"
		width="440px"
		class="reportDialog"
	>
		<div class="reportBox">
			<div class="reportHeard">
				<div class="title">举报{{ info.reportType | reportTypeText }}</div>
				<i @click="cancelClick" class="icon-sohu-quxiao"></i>
			</div>
			<div class="report-box">
				<div class="reason-report">
					<p class="title">举报原因<i class="icon-sohu-bitian"></i></p>
					<div class="report-list">
						<div
							v-for="(item, index) in reportList"
							:key="index"
							class="report-item"
							@click="reportTypeChange(item, index)"
						>
							<div class="circle-checked" v-if="reportIndex == index">
								<div class="circle-solid"></div>
							</div>
							<div class="circle" v-else></div>
							{{ item.dictLabel }}
						</div>
					</div>
					<p class="title">请填写举报信息</p>
					<el-input
						type="textarea"
						placeholder="请输入内容"
						v-model="form.reportInformation"
						resize="none"
						maxlength="200"
						show-word-limit
					>
					</el-input>
					<div class="img-box">
						<upload-image
							v-model="form.imageUrl"
							:limit="5"
							:fileSize="10"
							accept="image/jpg,image/png,image/jpeg"
						>
							<div class="uploadBtn">
								<i class="icon-sohu-jiahao"></i>
							</div>
						</upload-image>
					</div>
				</div>
			</div>
			<div
				class="btn"
				:style="{ opacity: this.reportIndex != null ? 1 : 0.3 }"
				@click="handleSubmit"
			>
				提交
			</div>
		</div>
	</dialogBox>
</template>
<script>
import dialogBox from '@/components/dialogBox.vue';
import UploadImage from '@/components/UploadImage.vue';
export default {
	components: { dialogBox, UploadImage },
	data() {
		return {
			reportDialogShow: false,
			reportList: [],
			reportIndex: null,
			form: {
				reportInformation: '',
				imageUrl: '',
				reportType: '',
			},
		};
	},
	watch: {
		reportDialogShow(val) {
			if (val) this.getDicts();
		},
	},
	filters: {
		reportTypeText(v) {
			let obj = {
				BusyTask: '任务',
				Video: '视频',
				Article: '图文',
				Question: '问答',
			};
			return obj[v];
		},
	},
	methods: {
		// 获取字典数据
		async getDicts() {
			let res = await this.$http.getDicts('report_type');
			this.reportList = res.data;
		},
		reportTypeChange(obj, index) {
			this.reportIndex = index;
			this.form.reportType = obj.dictValue;
		},
		cancelClick() {
			this.callback('confirm');
			this.reportDialogShow = false;
		},
		async handleSubmit() {
			if (this.reportIndex != null) {
				let params = {
					objId: this.info.id,
					userId: JSON.parse(localStorage.getItem('userInfo')).id,
					objType: this.info.reportType,
					title: this.info.title,
					authorId: this.info.userId,
					...this.form,
				};
				let res = await this.$http.addReportInfo(params);
				if (res.code == 200) {
					this.$message.success('举报成功');
					this.cancelClick();
				}
			}
		},
	},
};
</script>
<style lang="scss" scoped>
:deep(.el-dialog) {
	border-radius: 12px;
}
:deep(.el-textarea .el-input__count) {
	right: 25px;
	background: #f5f5f5;
}
.reportBox {
	background: #fff;
	display: flex;
	flex-direction: column;
	.reportHeard {
		position: relative;
		.title {
			color: #3d3d3d;
			font-size: 18px;
			font-weight: 600;
			span {
				font-size: 12px;
				color: #999;
				margin-left: 10px;
			}
		}
		.icon-sohu-quxiao {
			position: absolute;
			right: 0;
			font-size: 12px;
			top: 0;
			cursor: pointer;
		}
	}
	.report-box {
		margin-top: 20px;
		border-top: 2px solid #f5f5f5;
		padding-top: 20px;
		.title {
			font-size: 14px;
			color: #999999;
			margin-bottom: 12px;
			display: flex;
			align-items: center;
			i {
				font-size: 8px;
				color: #f53f3f;
				margin-left: 10px;
			}
		}
		.report-list {
			font-size: 14px;
			color: #999999;
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			gap: 8px;
			margin-bottom: 20px;
			.report-item {
				display: flex;
				align-items: center;
				gap: 4px;
				cursor: pointer;
				.circle,
				.circle-checked {
					width: 16px;
					height: 16px;
					background: rgba(255, 255, 255, 0.9);
					box-sizing: border-box;
					border: 1px solid #999;
					border-radius: 50%;
				}
				.circle-checked {
					border-color: $theme-color;
					position: relative;
					.circle-solid {
						width: 10px;
						height: 10px;
						border-radius: 50%;
						background: $theme-color;
						position: absolute;
						left: calc(50% - 5px);
						top: calc(50% - 5px);
					}
				}
			}
		}
		:deep(.el-textarea__inner) {
			width: 360px;
			height: 106px;
			border-radius: 8px;
			background: #f5f5f5;
			border: none;
		}

		.img-box {
			margin-top: 16px;
			:deep(.upload-container .uploadImageList .image-item) {
				width: 80px;
				height: 80px;
				border-radius: 8px;
				margin-bottom: 8px;
			}
			.uploadBtn {
				width: 80px;
				height: 80px;
				border-radius: 8px;
				background: #f5f5f5;
				text-align: center;
				line-height: 80px;
				margin-right: 10px;
				i {
					color: #d8d8d8;
					font-size: 24px;
				}
			}
		}
	}
	.btn {
		width: 360px;
		height: 40px;
		margin-top: 40px;
		border-radius: 8px;
		cursor: pointer;
		background: rgba(255, 108, 39);
		text-align: center;
		line-height: 40px;
		font-size: 18px;
		color: rgba(255, 255, 255);
		opacity: 0.3;
	}
}
</style>
