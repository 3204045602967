<template>
	<!-- <div class="siteDialogBox" v-if="show"> -->
	<div class="mainBox" @click.stop="">
		<div class="heard">
			<!-- 一级tab切换 -->
			<div class="tabs">
				<div
					:class="['tab_item', { active: item.value === siteCutActive }]"
					v-for="item in siteCutList"
					:key="item.value"
					@click.stop="siteTabChange(item)"
				>
					{{ item.name }}
				</div>
			</div>
			<div class="currentSite">
				<div class="site">
					当前站点：{{ whetherAll ? '全部' : siteList?.name }}
				</div>
				<div class="reset" @click="resetClick">
					<i class="icon-sohu-reset"></i>重置
				</div>
			</div>
		</div>
		<el-input
			v-model="searchSiteValue"
			@input="handleSearchSite"
			@keyup.enter.native="handleSearchSite"
			:placeholder="siteCutActive ? '请输入想要的品牌' : '请输入想要的城市'"
		>
			<i
				v-if="searchSiteValue"
				class="icon-sohu-guanbi2"
				slot="suffix"
				@click="handleDelete"
			>
			</i>

			<i
				v-else="searchSiteValue"
				class="icon-sohu-sousuo"
				slot="suffix"
				@click="handleSearchSite(searchSiteValue)"
			>
			</i>
		</el-input>
		<!-- 搜索结果 -->
		<div class="searchResult" v-if="searchSiteValue">
			<!-- <div class="nationBox" v-if="searchObj.name">
						<div class="backIcon" @click="searchObj = {}">
							<i class="icon-sohu-fanhui"></i>
						</div>
						<div class="nation">{{ searchObj.name }}</div>
					</div> -->
			<div
				class="item"
				@click="searchSiteItemClick(item)"
				v-for="item in searchList"
			>
				{{ item.name }}
			</div>
			<!-- <div class="internationalMain" v-if="searchObj.name">
						<div
							class="item"
							v-for="item in twoSearchList"
							:key="item.id"
							@click="definiteSelectionClick(item)"
						>
							{{ item.name }}
						</div>
					</div> -->
		</div>
		<div class="siteBox" v-else>
			<div class="sidebarLeftBox">
				<!-- 城市 -->
				<div
					v-if="siteCutActive === 0"
					:class="['item', { active: sidebarLeftActive === item.value }]"
					v-for="item in sidebarLeftList"
					:key="item.value"
					@click="sidebarLeftClick(item)"
				>
					{{ item.name }}
				</div>
				<!-- 品牌 -->
				<div
					v-if="siteCutActive === 1"
					:class="['item', { active: sidebarLeftActive === item.id }]"
					v-for="item in brandList"
					:key="item.value"
					@click="sidebarLeftClick(item)"
				>
					{{ item.name }}
				</div>
			</div>
			<div class="sidebarRightBox">
				<!-- 推荐 -->
				<div class="recommendBox" v-if="sidebarLeftActive === 0">
					<div class="item" v-for="item in recommendList" :key="item.title">
						<div class="title">{{ item.title }}</div>
						<div class="text" @click="recommendClick(item)">
							{{ item.text }}
						</div>
					</div>
				</div>
				<!-- 城市-->
				<div class="country" v-else>
					<!-- 品牌二级Tab -->
					<div class="brandBox" v-if="siteCutActive === 1">
						<div
							v-for="item in secondaryTabList"
							:class="['item', { active: item.id === secondaryTabActive }]"
							@click="secondaryTabActiveClick(item)"
							:key="item.id"
						>
							{{ item.name }}
						</div>
					</div>
					<!-- 选中国家返回按钮 -->
					<div class="nationBox" v-if="nationObj.name">
						<div class="backIcon" @click="stateBankClick">
							<i class="icon-sohu-fanhui"></i>
						</div>
						<div class="nation">{{ nationObj.name }}</div>
					</div>
					<!-- 筛选字母 /国家 /品牌-->
					<div class="alphabetBox">
						<div
							v-if="
								sidebarLeftActive === 1 || nationObj.name || siteCutActive === 1
							"
							:class="[
								'alphabet_item',
								{ active: sidebarRightActive === item.value },
							]"
							v-for="item in sidebarRightList"
							:key="item.value"
							@click="sidebarRightClick(item)"
						>
							{{ item.name }}
						</div>
						<!-- 国际 -->
						<div
							v-if="sidebarLeftActive === 2 && !nationObj.name"
							:class="[
								'alphabet_item',
								{ active: internationalActive === item.id },
							]"
							v-for="item in internationalList"
							:key="item.id"
							@click="internationalClick(item)"
						>
							{{ item.name }}
						</div>
					</div>
					<!-- 国内 -->
					<div
						class="cityBox"
						v-for="item in letterExhibitionList"
						:key="item.id"
						v-if="
							sidebarLeftActive === 1 || nationObj.name || siteCutActive === 1
						"
					>
						<!-- 字母 -->
						<div class="letter">{{ item.name }}</div>
						<div class="cityMain">
							<div
								class="cityItem"
								v-for="v in item.value"
								@click="definiteSelectionClick(v)"
								:key="v.id"
							>
								{{ v.name }}
							</div>
						</div>
					</div>
					<!-- 国际 -->
					<div
						class="internationalMain"
						v-if="sidebarLeftActive === 2 && !nationObj.name"
					>
						<div
							class="item"
							v-for="item in letterExhibitionList"
							:key="item.id"
							@click="parentSelectionClick(item)"
						>
							{{ item.name }}
						</div>
					</div>
					<!-- 空状态 -->
					<!-- <view class="emptyBox">
								<img
									src="https://sohugloba.oss-cn-beijing.aliyuncs.com/2024/04/16/5097015489e14b79a4ef8df9ca18e7de_403x357.png"
								/>
								<div>暂无数据,敬请期待</div>
							</view> -->
				</div>
			</div>
		</div>
	</div>
	<!-- </div> -->
</template>
<script>
import { getGroupByPinyin } from '@/utils/toPinyin/utils';
import myBMap from '@/utils/map';
export default {
	props: {
		show: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			whetherAll: true, //是否展示全部
			sidebarLeftActive: 0,
			currentPositionObj: {}, //定位位置
			siteList: {},
			siteCutActive: 0,
			domesticList: [], //国内数据
			internationalList: [], //国际数据
			letterExhibitionList: [], //数据处理最终值
			internationalActive: '', //国际栏选中的父级
			nationObj: {}, //国际选择国家
			brandList: [], //品牌数据
			secondaryTabList: [], //品牌二级tab
			secondaryTabActive: 0,
			searchSiteValue: '',
			searchList: [], //搜索结果
			siteCutList: [
				{
					name: '城市',
					value: 0,
				},
				{
					name: '品牌',
					value: 1,
				},
			],
			sidebarLeftList: [
				{
					name: '推荐',
					value: 0,
				},
				{
					name: '国内',
					value: 1,
				},
				{
					name: '国际',
					value: 2,
				},
			],
			recommendList: [
				{
					title: '我的位置',
					text: '定位中',
				},
				{
					title: '历史访问',
					text:
						JSON.parse(window.localStorage.getItem('historySite') || '[]')[1]
							?.name || '暂无历史',
				},
				{
					title: '热门',
					text: '武汉市',
				},
			],
			sidebarRightActive: 0,
			sidebarRightList: [
				{
					name: '热门',
					value: 0,
				},
				{
					name: 'ABCDEF',
					value: 1,
				},
				{
					name: 'GHIJ',
					value: 2,
				},
				{
					name: 'KLMN',
					value: 3,
				},
				{
					name: 'PQRSTUVW',
					value: 4,
				},
				{
					name: 'XYZ',
					value: 5,
				},
			],
		};
	},
	created() {
		this.getLocation();
	},
	mounted() {
		this.siteList = JSON.parse(window.localStorage.getItem('siteList'));
		this.whetherAll = JSON.parse(window.localStorage.getItem('whetherAll'));
	},
	methods: {
		//站点品牌tab切换
		async siteTabChange(val) {
			this.siteCutActive = val.value;
			this.sidebarLeftActive = 0;
			this.letterExhibitionList = [];
			this.domesticList = [];
			this.sidebarRightActive = 0;
			this.searchList = [];
			this.searchSiteValue = '';
			if (val.name === '品牌') {
				// 品牌
				let res = await this.$http.getSiteList({
					siteCate: false,
				});
				if (res.code === 200) {
					this.brandList = [{ name: '推荐', id: 0 }, ...res.data];
				}
			}
		},
		//搜索城市
		async handleSearchSite(val) {
			let res = await this.$http.getSiteDetails({
				name: this.searchSiteValue,
				siteCate: this.siteCutActive ? false : true,
				type: 'City',
			});
			if (res.code === 200) {
				this.searchList = res.data;
			}
		},
		//清除搜索
		handleDelete() {
			this.searchSiteValue = '';
		},
		//重置
		resetClick() {
			if (this.currentPositionObj.name) {
				this.definiteSelectionClick(this.currentPositionObj);
			}
			window.localStorage.setItem('whetherAll', 'true');
			setTimeout(() => {
				location.reload();
			}, 200);
		},
		//点击搜索内容
		async searchSiteItemClick(val) {
			// 国家
			// if (val.type === 'Country' || val.type === 'Continent') {
			// 	this.searchObj = val;
			// 	let res = await this.$http.getSiteDetails({
			// 		pid: val.id,
			// 	});
			// 	if (res.code === 200) {
			// 		this.twoSearchList = res.data;
			// 	}
			// } else {
			// 城市
			this.definiteSelectionClick(val);
			// }
		},
		//推荐国内国际切换
		sidebarLeftClick(val) {
			if (this.sidebarLeftActive === val.value) return false;

			if (this.siteCutActive === 0) {
				this.letterExhibitionList = [];
				this.nationObj = {};
				this.sidebarRightActive = 0;
				this.sidebarLeftActive = val.value;
				this.getSiteList();
			} else {
				this.letterExhibitionList = [];
				this.sidebarLeftActive = val.id;
				this.secondaryTabList = val.children;
				this.secondaryTabActive = val.children[0].id;
				this.sidebarRightActive = 0;
				this.secondaryTabActiveClick(val.children[0]);
			}
		},
		//推荐跳转
		recommendClick(val) {
			if (val.title === '历史访问') {
				if (JSON.parse(window.localStorage.getItem('historySite') || '[]')[1]) {
					this.definiteSelectionClick(
						JSON.parse(window.localStorage.getItem('historySite') || '[]')[1],
					);
				}
			} else if (val.title === '热门') {
				this.definiteSelectionClick({
					id: 11,
					siteId: 11,
					name: '武汉市',
					parentId: 5,
					parentName: '中国',
				});
			} else {
				if (this.currentPositionObj.name) {
					this.definiteSelectionClick(this.currentPositionObj);
				}
			}
		},
		//获取全部站点
		async getSiteList() {
			//国内
			if (this.sidebarLeftActive === 1) {
				let res = await this.$http.getSiteDetails({
					pageSize: 999,
					pid: 5,
				});
				if (res.code === 200) {
					this.domesticList = getGroupByPinyin(res.data, 'name') || [];
				}
			} else if (this.sidebarLeftActive === 2) {
				//国际
				let res = await this.$http.getSiteList({
					siteCate: true,
				});
				if (res.code === 200) {
					this.internationalList = res.data;
					this.internationalActive = res.data[0].id;
					this.letterExhibitionList = res.data[0].children;
				}
			}
		},
		//二级tabs选中
		secondaryTabActiveClick(val) {
			this.sidebarRightActive = 0;
			this.secondaryTabActive = val.id;
			this.letterExhibitionList = [];
			this.domesticList = getGroupByPinyin(val.children, 'name') || [];
		},
		sidebarRightClick(val) {
			//字母选择处理
			this.letterExhibitionList = [];
			this.sidebarRightActive = val.value;
			this.domesticList.forEach((item, index) => {
				if (val.name.indexOf(item.name) != -1) {
					this.letterExhibitionList.push(this.domesticList[index]);
				}
			});
		},
		//国际选择下级
		internationalClick(val) {
			this.internationalActive = val.id;
			this.letterExhibitionList = val.children;
		},
		//确定选择站点
		definiteSelectionClick(val) {
			//历史数据处理
			let historySiteList = JSON.parse(
				window.localStorage.getItem('historySite') || '[]',
			);
			let siteObjId = historySiteList[0]?.id || '';
			if (val.id != siteObjId) {
				historySiteList.unshift(val);
				if (historySiteList.length > 2) {
					historySiteList.splice(2, 1);
				}
				window.localStorage.setItem(
					'historySite',
					JSON.stringify(historySiteList),
				);
			}

			this.$store.commit('setSite', val);
			this.$store.commit('setSiteId', val.id);
			window.localStorage.setItem('siteList', JSON.stringify(val));
			window.localStorage.setItem('siteId', val.id);
			window.localStorage.setItem('whetherAll', 'false');
			// this.show= false;
			setTimeout(() => {
				location.reload();
			}, 200);
		},
		//国际选择国家
		parentSelectionClick(val) {
			this.letterExhibitionList = [];
			this.nationObj = val;
			this.domesticList = getGroupByPinyin(val.children, 'name') || [];
			this.sidebarRightActive = 0;
		},
		//选中国家返回按钮
		stateBankClick() {
			this.nationObj = {};
			this.internationalActive = this.internationalList[0].id;
			this.letterExhibitionList = this.internationalList[0].children;
		},
		//获取当前位置
		getLocation() {
			//Toast("如长时间未获取办理区域请手动选择");
			myBMap.init().then(() => {
				let that = this;
				let geolocation = new BMap.Geolocation();
				// 创建百度地理位置实例，代替 navigator.geolocation
				geolocation.getCurrentPosition(function (e) {
					if (this.getStatus() == BMAP_STATUS_SUCCESS) {
						// 百度 geolocation 的经纬度属性不同，此处是 point.lat 而不是 coords.latitude
						let point = new BMap.Point(e.point.lng, e.point.lat);
						let gc = new BMap.Geocoder();
						gc.getLocation(point, function (rs) {
							let lat = rs.point.lat;
							let lng = rs.point.lng;
							that.$http
								.getLocationDetail({ location: lat + ',' + lng })
								.then((res) => {
										that.currentPositionObj = JSON.parse(res.data);
										that.recommendList[0].text = that.currentPositionObj.name;
										that.recommendList[2].text = that.currentPositionObj.name;
								});
							//<<<<<<<<<<<<<<<<需要的位置信息在这获取
						});
					} else {
						that.$message.error(`定位失败，请手动选择区域或重新定位`);
					}
				});
			});
		},
	},
};
</script>
<style lang="scss">
// .siteDialogBox {
// 		position: fixed;
// 		top: 0;
// 		left: 0;
// 		width: 100vw;
// 		height: 100vh;
// 		z-index: 9999;
.mainBox {
	width: 100%;
	// width: 510px;
	// // height: 225px;
	// z-index: 99999;
	// position: fixed;
	// top: 61px;
	// right: 330px;
	// border-radius: 12px;
	// box-sizing: border-box;
	// background-color: #fff;
	// box-shadow: 0px 3px 14px 2px rgba(0, 0, 0, 0.05),
	// 	0px 8px 10px 1px rgba(0, 0, 0, 0.06),
	// 	0px 5px 5px -3px rgba(0, 0, 0, 0.04);
	.heard {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		.tabs {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-left: 22px;
			margin-top: 12px;
			.tab_item {
				color: #666666;
				font-size: 16px;
				font-weight: 500;
				line-height: 24px;
				padding: 0 14px;
				text-align: center;
				cursor: pointer;

				&:hover {
					opacity: 0.8;
				}

				&::after {
					content: '';
					display: block;
					background: transparent;
					width: 28px;
					height: 12px;
					margin: auto;
				}

				&.active {
					color: #ff6c27;
					font-size: 16px;
					font-weight: 500;
					line-height: 24px;
					&::after {
						width: 28px;
						height: 12px;
						transform: translate(0px, -2px);
						background-size: contain;
						background-image: url('https://sohugloba.oss-cn-beijing.aliyuncs.com/2024/04/13/7443c64478a44f62acac20d58839043e_84x36.png');
						// background: #016bff;
					}
				}
			}
		}
		.currentSite {
			font-size: 12px;
			font-weight: normal;
			line-height: 12px;
			margin-right: 27px;
			display: flex;
			align-items: center;
			.site {
				color: #ff6c27;
				margin-right: 8px;
			}
			.reset {
				display: flex;
				align-items: center;
				cursor: pointer;
				color: #999999;
				.icon-sohu-reset {
					font-size: 14px;
					margin-right: 4px;
				}
				&:hover {
					opacity: 0.8;
				}
			}
		}
	}
	.el-input {
		height: 40px;
		width: 480px;
		margin-left: 15px;
		margin-top: 6px;
		.el-input__suffix {
			right: 12px;
			line-height: 40px;
			font-size: 20px;
			color: rgba(0, 0, 0, 0.6);
		}

		.el-input__inner {
			border-radius: 25px;
			background: #f9faff;
			border: 0px solid #000;
		}
	}
	.siteBox {
		margin-top: 10px;
		border-top: 1px solid #e7e7e7;
		box-sizing: border-box;
		display: flex;
		.sidebarLeftBox {
			background: #f5f5f9;
			border-bottom-left-radius: 12px;
			overflow: hidden;
			.item {
				width: 84px;
				height: 28px;
				padding: 5px 6px 7px 14px;
				background: #f5f5f9;
				display: flex;
				align-items: center;
				color: rgba(0, 0, 0, 0.9);
				cursor: pointer;
				&.active {
					color: #ffffff;
					background: rgba(255, 108, 39, 0.8);
				}
			}
		}
		.sidebarRightBox {
			flex: 1;
			padding-top: 8px;
			.recommendBox {
				display: flex;
				margin-left: 20px;
				.item {
					margin-right: 60px;
					.title {
						font-size: 13px;
						font-weight: normal;
						line-height: 22px;
						color: rgba(0, 0, 0, 0.9);
						margin-bottom: 4px;
					}
					.text {
						color: #777777;
						font-size: 12px;
						font-weight: normal;
						line-height: 22px;
						cursor: pointer;
					}
				}
			}
			.country {
				.brandBox {
					display: flex;
					align-items: center;
					border-bottom: 1px solid #e7e7e7;
					display: flex;
					padding-left: 20px;
					overflow-y: auto;
					margin-bottom: 12px;
					.item {
						color: #666666;
						font-size: 13px;
						font-weight: normal;
						line-height: 22px;
						margin-bottom: 8px;
						cursor: pointer;
						margin-right: 24px;
						&:hover {
							color: #ff6c27;
						}

						&::after {
							content: '';
							display: block;
							background: transparent;
							width: 100%;
							height: 2px;
							transform: translate(0px, 0px);
							margin: auto;
						}

						&.active {
							color: #ff6c27;
							font-size: 13px;
							font-weight: 600;
							line-height: 22px;
							&::after {
								width: 100%;
								height: 2px;
								transform: translate(0px, 0px);
								background: #ff6c27;
							}
						}
					}
				}
				.nationBox {
					display: flex;
					align-items: center;
					margin-left: 20px;
					.backIcon {
						width: 16px;
						height: 16px;
						margin-right: 9px;
						background: #f8f8fc;
						border-radius: 50%;
						display: flex;
						align-items: center;
						justify-content: center;
						.icon-sohu-fanhui {
							font-size: 7px;
							color: #777777;
						}
						&:hover {
							background-color: #ff6c27;
							.icon-sohu-fanhui {
								color: #ffffff;
							}
						}
					}
					.nation {
						color: rgba(0, 0, 0, 0.9);
						font-size: 14px;
						font-weight: normal;
						line-height: 22px;
					}
				}
				.alphabetBox {
					display: flex;
					margin-bottom: 24px;
					margin-left: 20px;
					.alphabet_item {
						margin-right: 20px;
						font-size: 13px;
						font-weight: normal;
						line-height: 22px;
						cursor: pointer;
						&.active {
							font-weight: 600;
							line-height: 22px;
							color: #ff6c27;
						}
					}
				}
				.cityBox {
					display: flex;
					margin-left: 20px;

					.letter {
						font-size: 13px;
						font-weight: 600;
						line-height: 22px;
						color: #333333;
						margin-right: 12px;
						margin-left: 8px;
						margin: 0px 12px 8px 8px;
					}
					.cityMain {
						flex: 1;
						display: flex;
						flex-wrap: wrap;
						.cityItem {
							width: 60px;
							margin-right: 30px;
							font-size: 12px;
							font-weight: normal;
							line-height: 22px;
							color: #777777;
							display: -webkit-box !important;
							overflow: hidden;
							text-overflow: ellipsis;
							word-break: break-all;
							-webkit-line-clamp: 1;
							-webkit-box-orient: vertical !important;
							cursor: pointer;
							&:nth-child(4n + 4) {
								margin-right: 0px;
							}
							&:hover {
								color: #ff6c27;
							}
						}
					}
				}
				.internationalMain {
					flex: 1;
					display: flex;
					flex-direction: row;
					flex-wrap: wrap;
					margin-left: 20px;
					.item {
						width: 60px;
						margin-right: 30px;
						margin-bottom: 8px;
						font-size: 12px;
						font-weight: normal;
						line-height: 22px;
						color: #777777;
						display: -webkit-box !important;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
						-webkit-line-clamp: 1;
						-webkit-box-orient: vertical !important;
						cursor: pointer;
						&:nth-child(4n + 4) {
							margin-right: 0px;
						}
						&:hover {
							color: #ff6c27;
						}
					}
				}
				.emptyBox {
					flex: 1;
					height: 120px;
					display: flex;
					align-items: center;
					justify-content: center;
					color: #c5c5c5;
					font-size: 10px;
					font-weight: normal;
					line-height: 22px;
					img {
						width: 100px;
						height: 89px;
					}
				}
			}
		}
	}
	.icon-sohu-guanbi2 {
		color: #999999;
		cursor: pointer;
		font-size: 16px;
	}
}
// }
</style>
