let BASE_URL;
let ADMIN_URL;
let wsUrl;
switch (process.env.VUE_APP_FLAG) {
  case 'dev': //开发环境
		// BASE_URL = 'https://api.sohuglobal.com';		//正式
		// BASE_URL = 'http://192.168.130.47:8080';			//本地
		BASE_URL = 'http://192.168.150.252:8080';	//测试
		// BASE_URL = 'http://192.168.130.32:8080';	//debug
		ADMIN_URL = 'http://127.0.0.1:8088';
		wsUrl = 'ws://192.168.150.252:9326';
		// wsUrl = 'ws://192.168.130.32:9326';	//debug

		break;

	case 'stage': //测试环境
		BASE_URL = 'http://192.168.150.252:8080';
		ADMIN_URL = 'http://192.168.150.252:8088';
		wsUrl = 'ws://192.168.150.252:9326';
		break;

	case 'pro': //生产环境
		BASE_URL = 'https://api.sohuglobal.com';
		ADMIN_URL = 'http://admin.sohuglobal.com';
		wsUrl = 'wss://api.sohuglobal.com:9326';
		break;
}

export default {
	BASE_URL,
	ADMIN_URL,
	wsUrl,
};
