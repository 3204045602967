<template>
	<el-container class="layout">
		<el-header height="72px"> <q-header></q-header></el-header>
		<el-container style="overflow: auto">
			<el-main style="background-color: #f8f8fc">
				<div style="height: 100%; overflow: auto" @scroll="handleScroll">
					<router-view />
				</div>
			</el-main>
		</el-container>
	</el-container>
</template>
<script>
import qHeader from './q-header.vue';
import VueEmit from '@/utils/VueEmit';
export default {
	components: {
		qHeader
	},
	data() {
		return {};
	},
	methods: {
		handleScroll(event) {
			const scrollTop = event.target.scrollTop;
			const scrollHeight = event.target.scrollHeight;
			const clientHeight = event.target.clientHeight;
			if (scrollHeight - scrollTop - clientHeight < 10) {
				VueEmit.$emit('scrolltolower');
			}
		},
	},
};
</script>
<style lang="scss">
.layout {
	height: 100%;
	.el-header {
		padding: 0;
	}
	.el-main {
		padding: 0;
		overflow: hidden;
	}
}
</style>
