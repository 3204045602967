import Vue from 'vue';
import VueRouter from 'vue-router';
import Layout from '@/views/layout/layout.vue';
import agentEnter from './routers/agentEnter.js';
import demolitionEnter from './routers/demolitionEnter.js';
import roleEnter from './routers/roleEnter.js';
import AiCustomization from './routers/AiCustomization.js';
import institutionEntry from './routers/institutionEntry';
import contentDetails from './routers/contentDetails.js';
Vue.use(VueRouter);

const routerPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
	return routerPush.call(this, location).catch((err) => err);
};

const routes = [
	{
		path: '/',
		component: Layout,
		redirect: '/home',
		children: [
			{
				path: '/home',
				component: (resolve) => require(['@/views/home/index.vue'], resolve),
				meta: { title: '首页', mPath: '/' },
			},
			{
				path: '/shortPlay',
				component: (resolve) =>
					require(['@/views/shortPlay/index.vue'], resolve),
				meta: { title: '短剧', mPath: '/pages/life/life' },
			},
			{
				path: '/shortPlaySearchResult',
				component: (resolve) =>
					require(['@/views/shortPlay/searchResult.vue'], resolve),
				meta: {
					title: '短剧搜索结果',
					mPath: '/shortPlay/searchResultPage/index',
				},
			},
			{
				path: '/shortPlayDetail',
				component: (resolve) =>
					require(['@/views/shortPlay/detail.vue'], resolve),
				meta: { title: '短剧详情' },
			},
			{
				path: '/shortPlayMore',
				component: (resolve) =>
					require(['@/views/shortPlay/shortPlayMore.vue'], resolve),
				meta: { title: '短剧更多' },
			},
			{
				path: '/my',
				component: (resolve) => require(['@/views/my/index.vue'], resolve),
				meta: { title: '我的', mPath: '/pages/mine/mine' },
			},
			{
				path: '/othersHome',
				component: (resolve) => require(['@/views/my/othersHome.vue'], resolve),
				meta: { title: '他人的主页', mPath: '/userSafe/minePage/index/index' },
			},
			{
				path: '/makeMoney',
				component: (resolve) =>
					require(['@/views/makeMoney/index.vue'], resolve),
				meta: { title: '赚钱', mPath: '/pages/life/life' },
			},
			{
				path: '/makeMoney/classroom',
				component: (resolve) =>
					require(['@/views/makeMoney/classroom/index.vue'], resolve),
				meta: { title: '狐少少课堂', mPath: '/userSafe/minePage/index/index' },
			},
			{
				path: '/classroomMore',
				component: (resolve) =>
					require(['@/views/makeMoney/classroom/more.vue'], resolve),
				meta: {
					title: '狐少少课堂查看更多',
					mPath: '/contentPackages/lesson/lessonList',
				},
			},
			{
				path: '/makeMoney/taskDetails',
				component: (resolve) =>
					require(['@/views/makeMoney/taskDetails/index.vue'], resolve),
				meta: { title: '任务详情'},
			},
			{
				path: '/makeMoney/distributionPlaza',
				component: (resolve) =>
					require(['@/views/makeMoney/distributionPlaza/index.vue'], resolve),
				meta: {
					title: '分销广场',
					mPath: '/makeMoney/distributionPlaza/index',
				},
			},
			{
				path: '/content/videoList',
				component: (resolve) =>
					require(['@/views/content/videoList/index.vue'], resolve),
				meta: { title: '视频列表', mPath: '/' },
			},
			{
				path: '/content/imageTextList',
				component: (resolve) =>
					require(['@/views/content/imageTextList/index.vue'], resolve),
				meta: { title: '图文列表', mPath: '/' },
			},
			{
				path: '/content/questionList',
				component: (resolve) =>
					require(['@/views/content/questionList/index.vue'], resolve),
				meta: { title: '问答列表', mPath: '/' },
			},
			{
				path: '/searchResult',
				component: (resolve) => require(['@/views/searchResult.vue'], resolve),
				meta: { title: '搜索结果', mPath: '/focus/homeSearch/homeSearch' },
			},
			{
				path: '/videoShare',
				component: (resolve) =>
					require(['@/views/share/videoShare.vue'], resolve),
				meta: { title: '视频分享', mPath: '/contentPackages/videoPage/index' },
			},
		],
	},

	// 内容详情
	...contentDetails,

	// MCN机构入驻
	...institutionEntry,
	// 代理商入驻
	...agentEnter,
	// 拆单方入驻
	...demolitionEnter,
	// 角色入驻
	...roleEnter,
	//AI定制
	...AiCustomization,
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
});

const _isMobile = () => {
	let flag = navigator.userAgent.match(
		/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i,
	);
	return flag;
};

router.beforeEach((to, from, next) => {
	if (_isMobile()) {
		if (to.meta.mPath) {
			to.meta.mPath += '?';
			Object.keys(to.query).forEach((key) => {
				to.meta.mPath += key + '=' + to.query[key] + '&';
			});
			window.location.href = 'https://world.sohuglobal.com/#' + to.meta.mPath;
		} else {
			next();
		}
	} else {
		next();
	}
});

// 每次跳转页面都要置顶
router.afterEach((to, from, next) => {
	window.scrollTo(0, 0);
});

export default router;
