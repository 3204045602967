import store from '@/store';
import router from '@/router';
import axios from 'axios';
import { Message, MessageBox } from 'element-ui';
import URL from './baseUrl';

axios.defaults.baseURL = URL.BASE_URL;

//声明一个数组用于存储每个请求的取消函数和axios标识
let pending = [];
let cancelToken = axios.CancelToken;
let removePending = (config) => {
	for (let p in pending) {
		if (pending[p].u === config.url.split('?')[0] + '&' + config.method) {
			pending[p].f();
			pending.splice(p, 1);
		}
	}
};
let whiteList = [
	'/app/user/profile',
	'/busy-order/app/busy/task/task/page',
	'/im/group/[^/]+',
]; // 白名单接口
axios.interceptors.request.use(
	(config) => {
		//用于发送多次重复请求时，只执行最后一次请求
		removePending(config);
		if (whiteList.every((item) => !config.url.match(new RegExp(item)))) {
			config.cancelToken = new cancelToken((c) => {
				pending.push({
					u: config.url.split('?')[0] + '&' + config.method,
					f: c,
				});
			});
		}
		let token = store.state.token || localStorage.token;

		token && (config.headers['Authorization'] = `Bearer ${token}`);
		return config;
	},
	(error) => {
		return Promise.reject(error);
	},
);

//响应拦截
axios.interceptors.response.use(
	(response) => {
		if (response.data.code == 401) {
			store.dispatch('logOut');
			const currentRoute = router.app.path;
			console.log(router.app.path);
			if (currentRoute === '/home') {
				window.location.reload();
			} else {
				router.push({ path: '/' });
			}
			// Message.error(response.data.msg);
		} else if (response.data.code !== 200) {
			Message.error(response.data.msg);
		}
		return response;
	},
	(error) => {
		let { message } = error;
		if (message == 'Network Error') {
			Message.error('后端接口连接异常');
		} else if (message.includes('timeout')) {
			Message.error('系统接口请求超时');
		} else if (message.includes('Request failed with status code')) {
			Message.error('系统接口' + message.substr(message.length - 3) + '异常');
		}

		return Promise.reject(error);
	},
);

/**
 * get请求
 * @param {*} url 接口地址
 * @param {*} params 请求参数
 */
export function get(url, params) {
	return new Promise((resolve, reject) => {
		axios({
			url,
			method: 'get',
			params: params,
		}).then(
			(res) => {
				resolve(res.data);
			},
			(err) => {
				reject(err.data);
			},
		);
	});
}

/**
 * post Json格式请求
 * @param {*} url 接口地址
 * @param {*} params post请求数据
 */
export function postJson(url, params) {
	return new Promise((resolve, reject) => {
		axios({
			headers: {
				'Content-Type': 'application/json',
			},
			transformRequest: [
				function (data) {
					data = JSON.stringify(data);
					return data;
				},
			],
			url,
			method: 'post',
			data: params,
		}).then(
			(res) => {
				resolve(res.data);
			},
			(err) => {
				reject(err.data);
			},
		);
	});
}

/**
 * putJson
 * @param {*} url
 * @param {*} params
 */
export function putJson(url, params) {
	return new Promise((resolve, reject) => {
		axios({
			headers: {
				'Content-Type': 'application/json',
			},
			transformRequest: [
				function (data) {
					data = JSON.stringify(data);
					return data;
				},
			],
			url,
			method: 'put',
			data: params,
		}).then(
			(res) => {
				resolve(res.data);
			},
			(err) => {
				reject(err.data);
			},
		);
	});
}

/**
 * post fromData表单请求
 * @param {*} url 接口地址
 * @param {*} params post请求数据
 */
export function postFile(url, params) {
	return new Promise((resolve, reject) => {
		axios({
			headers: {
				'Content-Type': 'multipart/form-data',
			},
			url,
			method: 'post',
			data: params,
		}).then(
			(res) => {
				resolve(res.data);
			},
			(err) => {
				reject(err.data);
			},
		);
	});
}

/**
 * delete
 * @param {*} url
 * @param {*} params
 */
export function deleteApi(url, params) {
	return new Promise((resolve, reject) => {
		axios({
			url,
			method: 'delete',
			params: params,
		}).then(
			(res) => {
				resolve(res.data);
			},
			(err) => {
				reject(err.data);
			},
		);
	});
}
