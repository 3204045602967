<template>
	<dialogBox
		@confirm="confirmClick"
		showBottomBtn
		appendToBody
		v-model="dialogVisible"
		class="setRemarksDialog"
	>
		<div slot="header_title" style="display: flex; align-items: center">
			<el-image
				style="margin-right: 8px"
				:src="require('@/assets/images/chat/Calendar.png')"
			></el-image>
			设置备注
		</div>
		<div class="setRow">
			<div class="setRow_title">备注</div>
			<q-input placeholder="请输入备注名称" v-model="details.alias"></q-input>
		</div>
		<div class="setRow">
			<div class="setRow_title">标签</div>
			<el-select v-model="details.tagIds" multiple placeholder="请选择">
				<el-option
					v-for="item in tagList"
					:key="item.id"
					:label="item.name"
					:value="item.id"
				>
				</el-option>
			</el-select>
		</div>
		<div class="setRow">
			<div class="setRow_title">电话</div>
			<q-input placeholder="添加电话" v-model="details.notePhone"></q-input>
		</div>
		<div class="setRow">
			<div class="setRow_title">描述</div>
			<q-input placeholder="添加文字" v-model="details.noteTxt"></q-input>
		</div>
		<div class="imgList">
			<UploadImage :limit="5" v-model="details.noteImage"></UploadImage>
		</div>
	</dialogBox>
</template>
<script>
import dialogBox from '@/components/dialogBox.vue';
import UploadImage from '@/components/UploadImage.vue';
export default {
	components: { dialogBox, UploadImage },
	data() {
		return {
			dialogVisible: false,
			details: {},
			tagList: [],
		};
	},
	inject: ['getAppFriends'],
	methods: {
		async open(id) {
			this.dialogVisible = true;
			this.getAppFriendsDetails(id);
			const res = await this.$http.appUserTagList();
			this.tagList = res.data;
		},

		// 好友详情
		async getAppFriendsDetails(id) {
			const res = await this.$http.appFriendsDetails(id);
			this.details = res.data;
			this.details.tag = this.details.tag?.split(',');
		},

		// 确定按钮
		async confirmClick(close) {
			let tag = this.details.tagIds?.join();
			delete this.details.tagIds;
			const res = await this.$http.editFriends({
				...this.details,
				tag: tag,
			});
			if (res.code == 200) {
				close();
				this.getAppFriends(this.details.friendId);
			}
		},
	},
};
</script>
<style lang="scss">
.setRemarksDialog {
	.setRow {
		margin-top: 24px;
		.setRow_title {
			color: #333333;
			font-size: 14px;
			font-weight: 500;
			margin-bottom: 8px;
		}
		.el-select {
			border: 0 solid #000;
			width: 100%;
			.el-input__inner {
				border: 0 solid #000;
				background-color: #f3f3f3;
			}
		}
	}
	.imgList {
		margin-top: 10px;
	}
}
</style>
