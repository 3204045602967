<template>
	<div class="subType_wallet">
		<div class="card_title">{{ content.title }}</div>
		<div class="card_time">{{ content.updateTime }}</div>
		<div class="wallet-content">
			<div class="head">
				<div class="title">付款金额</div>
				<div class="price">
					<span class="font">￥</span>
					<span class="value">{{ content.amount }}</span>
				</div>
			</div>
			<div class="main">
				<div class="item">
					<div class="title">{{ payTypeTitle(content.noticeType) }}</div>
					<div class="value">{{ content.payTypeName }}</div>
				</div>
				<div class="item">
					<div class="title">{{ payObjTitle(content.noticeType) }}</div>
					<div class="value">{{ content.payDetail }}</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	props: ['content'],
	data() {
		return {};
	},
	methods: {
		/**
		 * 通知类型:支付成功--paySuccess,收益到账--incomeSuccess,收益退款--incomeRefund,退款到账--refundSuccess
		 */
		payTitle(value) {
			switch (value) {
				case 'paySuccess':
					return '付款金额';
				case 'refundSuccess':
					return '退款方式';
				case 'incomeSuccess':
					return '到账方式';
				case 'incomeRefund':
					return '退回方式';
				default:
					return '付款金额';
			}
		},
		payTypeTitle(value) {
			switch (value) {
				case 'paySuccess':
					return '支付方式';
				case 'refundSuccess':
					return '退款方式';
				case 'incomeSuccess':
					return '到账方式';
				case 'incomeRefund':
					return '退回方式';
				default:
					return '支付方式';
			}
		},
		payObjTitle(value) {
			switch (value) {
				case 'paySuccess':
					return '支付对象';
				case 'refundSuccess':
					return '退款说明';
				case 'incomeSuccess':
					return '到账说明';
				case 'incomeRefund':
					return '退回说明';
				default:
					return '支付对象';
			}
		},
	},
};
</script>
<style lang="scss">
.subType_wallet {
	width: 100%;
	height: fit-content;
	background-color: #FFF;
	border-radius: 8px;
  .card_title {
    color: #333333;
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
  }
  .card_time {
    color: #555555;
    line-height: 16px;
    font-size: 10px;
    margin-bottom: 20px;
  }
  .wallet-content {
    margin-top: 20px;
    .head {
      .title {
        font-size: 14px;
        color: #bcbcbc;
        line-height: 22px;
        text-align: center;
      }
      .price {
        text-align: center;
        margin-top: 8px;
        .font {
          font-size: 24px;
          color: #333;
          line-height: 34px;
        }
        .value {
          font-size: 36px;
          color: #333;
          line-height: 44px;
        }
      }
    }
    .main {
      margin: 20px 0;
      .item {
        display: flex;
        margin-bottom: 10px;
        .title {
          font-size: 12px;
          color: #999;
          line-height: 18px;
          margin-right: 24px;
        }
        .value {
          font-size: 12px;
          color: #333;
          line-height: 18px;
        }
      }
    }
  }
}
</style>
