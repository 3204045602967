<template>
  <div class="enter-layout">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "InstitutionLayout",
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.enter-layout {
  background-color: white;
  .enter-container {
    width: 1200px;
    margin: auto;
  }
  .enter-app-container{
    width: 100%;
    margin: auto;
  }
}
</style>

