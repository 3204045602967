<template>
	<div class="userCard_item" @click="taskDetailsClick(item)">
		<div
			v-if="item.receiveLimit && userId == item.receiveOnlyUserId"
			class="speciallyInvite"
		>
			特邀委派
		</div>
		<div class="header">
			<div class="title line-1">{{ item.title }}</div>
			<div class="type-main">
				<span class="type">{{ item.typeName }}</span
				><span class="type">{{ item.industryName }}</span>
			</div>
		</div>
		<p class="content line-2">{{ item.content }}</p>
		<p class="price">¥ {{ item.fullAmount | formatPrice }}</p>

		<div
			:class="['bottomBox', { end: !chatShow }]"
			v-if="type !== 'investmentFlow'"
		>
			<div v-if="chatShow" class="btn" @click.stop="communicationClick">
				<i class="icon-sohu-liaotian"></i>
				立即沟通
			</div>
			<slot name="rightBtn">
				<div class="btn" @click.stop="orderClick(item)">
					<i class="icon-sohu-wenjian"></i>
					我要接单
				</div>
			</slot>
		</div>
		<!-- 接单弹框 -->
		<el-dialog
			:append-to-body="true"
			width="720px"
			title="申请接单"
			:visible.sync="orderDialogVisible"
			class="orderDialog"
			@close="closeBtnClick"
		>
			<el-form :model="orderFrom" ref="refForm" :rules="rules">
				<el-form-item label="描述" prop="applyMsg">
					<el-input
						class="textarea"
						type="textarea"
						maxlength="30"
						v-model="orderFrom.applyMsg"
						placeholder="请输入"
					></el-input>
				</el-form-item>
				<el-form-item label="上传附件" prop="applyAnnex">
					<div>
						<FileUpload
							:fileMaxSize="5"
							v-model="orderFrom.applyAnnex"
							:fileTypes="['jpg', 'png', 'gif', 'pdf']"
							iconTip="为了提高您的接单成功率，您需要提交一些资质以及成功案例。"
							showTip
							fileListShow
						>
						</FileUpload>
					</div>
				</el-form-item>
				<el-form-item label="在线支付" prop="amount">
					<div class="payBox">
						<div class="pay-value">
							<el-input
								v-model="orderFrom.amount"
								placeholder="请输入保证金必须，大于0元"
							></el-input>
							<div>元</div>
						</div>
						<p class="pay-tip">保证金金额默认为接单价格的20%</p>
						<el-checkbox v-model="checked">
							<i @click="skipClick">任务接单须知</i></el-checkbox
						>
					</div>
				</el-form-item>
			</el-form>
			<div class="notice"></div>
			<div class="dialogBtn">
				<div class="closeBtn" @click="closeBtnClick">取消</div>
				<div class="affirm" @click="affirmClick(item)">确认</div>
			</div>
		</el-dialog>

		<!-- 认证弹框 -->
		<AuthenticationDialog ref="authenticationDialogRef"></AuthenticationDialog>
	</div>
</template>
<script>
import AuthenticationDialog from '@/components/authenticationDialog.vue';
import FileUpload from '@/components/FileUpload.vue';
export default {
	components: { FileUpload, AuthenticationDialog },
	props: {
		item: {
			type: Object,
		},
		//广场：square，分销：distribution
		type: {
			type: String,
			default: 'square',
		},
		//立即沟通
		chatShow: {
			type: Boolean,
			default: true,
		},
	},
	filters: {
		formatPrice(v) {
			if (+v + '' !== 'NaN') {
				if (v > 10000) {
					if ((v / 10000 + '').indexOf('.') > -1) {
						return (v / 10000).toFixed(2) + '万';
					} else {
						return v / 10000 + '万';
					}
				} else {
					if ((v + '').indexOf('.') > -1) {
						return (+v).toFixed(2);
					} else {
						return v;
					}
				}
			} else {
				return 0;
			}
		},
	},
	data() {
		var moneyValidator = (rule, value, callback) => {
			let reg = /(^[1-9](\d+)?(\.\d{1,2})?$)|(^0$)|(^\d\.\d{1,2}$)/;
			if (value == null || value == '') {
				callback('请输入金额');
			} else if (!reg.test(value)) {
				callback('请输入正确的金额');
			} else {
				callback();
			}
		};
		return {
			orderDialogVisible: false,
			orderFrom: {
				amount: '',
				applyMsg: '',
				applyAnnex: '',
			},
			checked: false,
			userId: this.$store.state.userInfo.id,
			rules: {
				applyMsg: [
					{ required: true, message: '请输入接单描述', trigger: 'blur' },
				],
				amount: [
					{ required: true, validator: moneyValidator, trigger: 'blur' },
				],
			},
		};
	},
	methods: {
		//接单
		async orderClick(val) {
			if (val.userId == this.$store.state.userInfo.id)
				return this.$message({
					message: '无法接自己发布任务',
					type: 'error',
					offset: 80,
				});
			this.$login().then(async (res) => {
				if (await this.$refs.authenticationDialogRef.getAccountDetails()) {
					this.orderDialogVisible = true;
					let amount = (val.fullAmount * 0.2).toFixed(2);
					this.orderFrom.amount = amount == 0 ? (0.01).toFixed(2) : amount;
				}
			});
		},
		//详情跳转
		taskDetailsClick(val) {
			this.$router.push({
				path: '/makeMoney/taskDetails',
				query: { taskNumber: val.taskNumber, type: this.type },
			});
		},
		//取消弹框
		closeBtnClick() {
			this.$refs.refForm.resetFields();
			this.checked = false;
			this.orderDialogVisible = false;
		},
		//我要接单确认
		async affirmClick(val) {
			if (!this.checked)
				return this.$message({
					message: '请阅读并勾选接单须知',
					type: 'error',
				});
			this.$refs.refForm.validate(async (valid) => {
				if (valid) {
					let data = {
						amount: this.orderFrom.amount,
						applyAnnex: this.orderFrom.applyAnnex,
						applyMsg: this.orderFrom.applyMsg,
						taskNumber: val.taskNumber,
					};
					console.log(data, '申请数据');
					let res = await this.$http.busyTaskReceive(data);
					if (res.code === 200) {
						this.$message({
							message: '提交成功',
							type: 'success',
						});
						this.closeBtnClick();
					}
				}
			});
		},
		//立即沟通
		communicationClick() {
			this.$login().then(async (res) => {
				this.$store.dispatch('communicateImmediately', {
					userId: this.item.userId,
					shareId: this.item.taskNumber,
					shareType: 'task',
				});
			});
		},
		//协议跳转
		skipClick() {
			window.open('https://world.sohuglobal.com/任务接单.html');
		},
	},
};
</script>
<style lang="scss" scoped>
.userCard_item {
	padding: 16px 24px 12px 20px;
	position: relative;
	cursor: pointer;
	box-sizing: border-box;
	border-radius: 8px;
	background: #fff;
	.speciallyInvite {
		position: absolute;
		right: 0;
		top: 0;
		width: 72px;
		height: 24px;
		background: #ff6c27;
		border-radius: 0px 8px 0px 8px;
		display: flex;
		font-size: 12px;
		font-weight: normal;
		align-items: center;
		justify-content: center;
		color: rgba(255, 255, 255, 0.97);
	}
	.header {
		display: flex;
		flex-direction: column;
		.title {
			font-size: 14px;
			font-weight: 500;
			line-height: 24px;
			color: #3d3d3d;
			max-width: 208px;
			margin-bottom: 8px;
		}
		.type-main {
			display: flex;
			margin-bottom: 8px;
			.type {
				font-size: 12px;
				height: 24px;
				color: rgba(102, 102, 102, 0.6);
				border-radius: 4px;
				padding: 0px 8px;
				display: flex;
				align-items: center;
				background: #f9f9fa;
				margin-right: 8px;
				background: #f9f9fa;
			}
		}
	}

	.price {
		font-size: 20px;
		font-weight: bold;
		color: #f53f3f;
		margin-top: 8px;
	}

	.content {
		font-size: 14px;
		font-weight: normal;
		color: #3d3d3d;
		max-width: 330px;
		min-height: 48px;
		line-height: 24px;
	}

	.bottomBox {
		padding-top: 12px;
		font-size: 14px;
		border-top: 1px solid #e7e7e7;
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-top: 14px;
		.btn {
			padding: 0 12px;
			border-radius: 4px;
			background: #fff0e9;
			color: #ff6c27;
			font-size: 14px;
			font-weight: 500;
			color: $theme-color;
			display: flex;
			align-items: center;
			height: 32px;
			line-height: normal;

			i {
				font-size: 16px;
				color: $theme-color;
				margin-right: 8px;
			}
			&:hover {
				opacity: 0.8;
			}
		}
	}
	.end {
		justify-content: flex-end;
	}

	.el-image {
		width: 52px;
		height: 52px;
		border-radius: 4px;
	}

	&:hover {
		box-shadow: 0px 8px 32px 0px rgba(243, 116, 13, 0.15),
			0px 4px 10px 0px rgba(0, 0, 0, 0.3);
	}
}
.orderDialog {
	.textarea {
		width: 564px;
		height: 79px;
	}
	.payBox {
		display: flex;
		flex-direction: column;
		font-size: 14px;
		.pay-value {
			display: flex;
			.el-input {
				width: 203px;
				height: 32px;
				margin-right: 8px;
				.el-input__inner {
					width: 203px;
					height: 32px;
					padding: 0 10px;
				}
			}
		}
		.pay-tip {
			margin-top: 6px;
			font-size: 12px;
			color: #999999;
			line-height: 17px;
		}
		.el-checkbox {
			margin-top: 7px;
			line-height: 20px;
		}
	}
	.notice {
		margin-left: 64px;
	}
	.dialogBtn {
		margin-top: 20px;
		display: flex;
		justify-content: flex-end;
		.closeBtn {
			width: 60px;
			height: 32px;
			border-radius: 4px;
			background: transparent;
			font-size: 14px;
			font-weight: normal;
			line-height: 20px;
			display: flex;
			align-items: center;
			justify-content: center;
			border: 1px solid #d1d1d1;
			color: #666666;
			margin-right: 20px;
			cursor: pointer;
		}
		.affirm {
			width: 60px;
			height: 32px;
			border-radius: 4px;
			background: #ff6c27;
			font-size: 14px;
			font-weight: normal;
			line-height: 20px;
			display: flex;
			align-items: center;
			justify-content: center;
			color: #fff;
			cursor: pointer;
			&:hover {
				opacity: 0.8;
			}
		}
	}
}
:deep(.el-dialog) {
	box-shadow: none;
	border-radius: 12px;
}
:deep(.el-dialog__title) {
	color: #3d3d3d;
	font-size: 20px;
	font-weight: 600;
}
:deep(.el-dialog__close) {
	font-size: 24px;
	font-weight: bold;
}
.el-form-item {
	display: flex;
}
:deep(.textarea .el-textarea__inner) {
	width: 564px;
	height: 79px;
}
:deep(.payBox .el-input .el-input__inner) {
	width: 203px;
	height: 32px;
	padding: 0 10px;
}

/* 选中样式 */
:deep(
		.el-checkbox__input.is-checked .el-checkbox__inner,
		.el-checkbox__input.is-indeterminate .el-checkbox__inner
	) {
	background: #ff6c27;
	border: 1px solid #ff6c27;
}
:deep(.el-checkbox__input.is-checked + .el-checkbox__label) {
	color: #ff6c27;
}

:deep(.el-checkbox.is-bordered.is-checked) {
	border-color: #ff6c27;
}
:deep(.el-checkbox__input.is-focus .el-checkbox__inner) {
	border-color: #ff6c27;
}
:deep(.upload-head) {
	display: flex;
	align-items: center;
	align-items: end;
	flex-wrap: wrap;
}
:deep(.fileUploadBtn) {
	margin-right: 9px;
}
</style>
