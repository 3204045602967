<template>
	<div class="upload-container">
		<div class="uploadImageList">
			<div
				@click="handleUpload"
				class="uploadBox"
				style="cursor: pointer"
				v-if="uploadList && uploadList.length < limit"
			>
				<slot>
					<div class="upload-image-box">
						<div class="upload-text">
							<span class="color-blue">点击上传</span>
							<slot class="upload-text" name="uploadText" />
						</div>
					</div>
				</slot>
			</div>
			<!-- 图片回显 -->
			<div class="image-item" v-for="(item, index) in uploadList" :key="index">
				<el-image
					@click="previewImage(item)"
					class="image"
					:src="item"
					fit="contain"
				></el-image>
				<div
					v-if="!disabled"
					class="el-icon-close"
					@click="handleRemove(item)"
				></div>
			</div>
		</div>
		<!-- 提示文字 -->
		<div class="tips-text" v-if="tipText">{{ tipText }}</div>
		<input
			ref="inputFileRef"
			type="file"
			:accept="accept"
			:multiple="multiple"
			hidden
			@change="fileChange"
		/>

		<el-dialog
			append-to-body
			:visible.sync="dialogVisible"
			title="预览"
			width="800px"
			close-on-click-modal
			:lock-scroll="false"
		>
			<img
				:src="dialogImageUrl"
				style="display: block; max-width: 100%; margin: 0 auto"
			/>
		</el-dialog>
	</div>
</template>

<script>
export default {
	name: 'UploadImage',
	props: {
		value: '', // 图片地址
		accept: {
			// 接受文件类型 image/*
			type: String,
			default: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
		},
		multiple: {
			// 是否允许多选
			type: Boolean,
			default: false,
		},
		fileSize: {
			type: Number,
			default: 5,
		},
		tipText: {
			type: String,
			default: '',
		},
		limit: {
			type: [Number, String],
			default: 1,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			uploadList: [], // 上传的图片地址集合
			dialogVisible: false,
			dialogImageUrl: '',
			loading: false,
		};
	},
	watch: {
		value: {
			handler(nVal) {
				if (nVal) {
					this.uploadList = this.strToArray(nVal);
				}else{
					this.uploadList = [];
				}
			},
			deep: true,
			immediate: true,
		},
	},
	methods: {
		// 点击上传
		handleUpload() {
			if (this.loading) return;
			this.$refs.inputFileRef.click();
		},
		// 文件上传
		async fileChange(e) {
			let file = e.target.files[0];
			const isPicType = this.accept.includes(file.type);
			const isFileSize = file.size / 1024 / 1024 < this.fileSize;
			if (!isPicType) return this.$message.error(`上传的文件格式不正确！`);
			if (!isFileSize)
				return this.$message.error(`上传文件大小不能超过${this.fileSize}MB!`);
			this.loading = true;
			const res = await this.$http.uploadFile(file);
			const { code, data } = res;
			if (code !== 200) return false;
			this.$message.success(`上传成功`);
			this.uploadList.push(data.url);
			this.$emit('input', this.arrayToStr(this.uploadList));
			this.$emit('fileChange', data);
			this.$refs.inputFileRef.value = '';
			this.loading = false;
		},
		// 移除
		handleRemove(url) {
			const that = this;
			let arr = this.uploadList.filter((item) => item !== url) || [];
			this.uploadList = arr;
			this.$nextTick(() => {
				this.$emit('input', that.arrayToStr(arr));
			});
		},
		// 字符串转数组
		strToArray(str) {
			let tempArr = [];
			if (str) tempArr = str.split(',');
			return tempArr;
		},
		// 数组转字符串
		arrayToStr(arr) {
			let tempStr = '';
			if (arr && arr.length > 0) tempStr = arr.toString();
			return tempStr;
		},

		// 图片预览
		previewImage(val) {
			this.dialogImageUrl = val;
			this.dialogVisible = true;
		},
	},
};
</script>

<style lang="scss" scoped>
.upload-container {
	.uploadImageList {
		display: flex;
		flex-wrap: wrap;

		.uploadBox {
			cursor: pointer;
			&:hover {
				opacity: 0.8;
			}
		}
		.upload-image-box {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 106px;
			height: 106px;
			position: relative;
			overflow: hidden;
			box-sizing: border-box;
			margin-right: 10px;
			background: #f7f7f7;
			border-radius: 8px;
			.upload-text {
				display: flex;
				flex-direction: column;
				align-items: center;
				font-size: 14px;
				line-height: 22px;
				text-align: center;
				.color-blue {
					color: #5a6e97;
				}
				.upload-text {
					font-size: 12px;
				}
			}
			.img {
				position: absolute;
				width: 100%;
				height: 100%;
				z-index: 1;
			}
		}

		.image-item {
			position: relative;
			width: 106px;
			height: 106px;
			margin-right: 10px;
			margin-bottom: 5px;
			border: 1px dashed #dcdcdc;

			&:last-child {
				margin-right: 0;
			}

			.image {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
			.el-icon-close {
				position: absolute;
				right: -4px;
				top: -4px;
				font-size: 14px;
				color: white;
				background-color: gray;
				padding: 2px;
				border-radius: 50%;
				cursor: pointer;
				&:hover {
					color: white;
					background-color: #000;
				}
			}
		}
	}
}
</style>
