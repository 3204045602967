import { get, postFile, postJson, putJson, deleteApi } from '@/utils/request';
import store from '@/store';
export default {
	/**
	 * 启动ai智能模型接口(未登录时调用)
	 * @param params
	 * @returns {Promise | Promise<unknown>}
	 */
	aiNoLogin(params){
		return postJson("/app/api/noLogin/add",params)
	},
	/**
	 * 埋点调用接口
	 * @param scene //场景
	 * @param content //内容类型
	 * @param event //事件类型
	 * @param params //其他参数
	 * @returns {Promise | Promise<unknown>}
	 */
	aiRecAdd(scene,content,event,params){
		let obj = {
			itemId:params.itemId, //内容id
			traceId:params.traceId, //后台返回
			traceInfo:params.traceInfo, //后台返回
			bhvValue:params.bhvValue, //停留时长
			bhvType:event, //埋点事件
			sceneId:scene, //埋点场景
			itemType:content, //内容类型
			platform:"h5", //平台标识
		}
		if (store.getters.isLogin) {
			obj.userId = store.state.userInfo.id
		} else {
			obj.imei = localStorage.getItem("aiRec") //未登录时用户标识
		}
		return postJson("/app/api/airecBehavior/add",obj)
	}
}