<template>
	<div id="app" @click="hide">
		<router-view />
	</div>
</template>
<script>
import VueEmit from '@/utils/VueEmit';
export default {
	data() {
		return {};
	},
	methods: {
		hide() {
			VueEmit.$emit('hide');
		},
	},
};
</script>

<style lang="scss">
html,
body,
#app {
	width: 100%;
	height: 100%;
	box-sizing: border-box;
}

.icon {
	width: 1em;
	height: 1em;
	vertical-align: -0.15em;
	fill: currentColor;
	overflow: hidden;
}

::-webkit-scrollbar {
	width: 8px;
	height: 8px;
	background-color: #fff;
}

::-webkit-scrollbar-thumb {
	border-radius: 8px;
	background-color: #ccc;
}

#YSF-BTN-HOLDER {
	z-index: 99 !important;
	margin-bottom: 25px;
}
</style>
