import { get, postFile, postJson, putJson } from '@/utils/request';

export default {
	// 机构名称唯一校验
	getCheckInstitutionNameUnique(data) {
		return get(`/entry/api/accountEnter/checkInstitutionNameUnique`, data);
	},
	// 发送角色入驻的短信验证码
	postSendCodeEntry(data) {
		return postJson(`/resource/sms/code/entry`, data);
	},
	// // 发送代理商的短信验证码
	// postSendCodeAgent(data) {
	// 	return postJson(`/resource/sms/code/agent`, data);
	// },
	// // 发送拆单方的短信验证码
	// postSendCodeResolve(data) {
	// 	return postJson(`/resource/sms/code/resolve`, data);
	// },
	// MCN机构的短信验证码校验
	getCheckSmsCodeMCN(data) {
		return get(`/entry/api/accountEnter/checkSmsCode`, data);
	},
	// 企业认证-提交（新增-修改）
	postAccountEnter(data) {
		return postJson(`/entry/accountEnter`, data);
	},

	// 查询用户入驻信息
	entryAccountEnterCheckUserEntry(roleKey, data) {
		return get(`/entry/accountEnter/checkUserEntry/${roleKey}`, data);
	},

	// 阿里云营业执照识别
	ocrBusinessIdcard(data) {
		return get(`/app/ocr/business/idcard`, data);
	},

	// 阿里云身份证正面识别，人像面
	ocrIdcardFront(data) {
		return get(`/app/ocr/idcard/front`, data);
	},

	// 阿里云身份证背面识别，国徽面
	ocrIdcardBack(data) {
		return get(`/app/ocr/idcard/back`, data);
	},

	// 阿里云银行卡识别正面识别
	ocrBankFront(data) {
		return get(`/app/ocr/bank/front`, data);
	},

	// 获取入驻详情
	accountGetInfoByUserId(data) {
		return get(`/pay/account/getInfoByUserId`, data);
	},

	// 获取当前用户账户入驻详细信息
	entryAccountEnterQueryByUserId(data) {
		return get(`/admin/entry/accountEnter/queryByUserId`, data);
	},

	// 新增账户入驻
	entryAccountEnter(data) {
		return postJson(`/admin/entry/accountEnter`, data);
	},

	// 修改账户入驻
	editEntryAccountEnter(data) {
		return putJson(`/admin/entry/accountEnter`, data);
	},
};
