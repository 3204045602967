<template>
	<div class="messageUser_header">
		<div class="messageUser_header_userName line-1">
			{{ switchGroup.name || imDetails.name || imDetails.nickName }}
			<span v-if="imDetails.groupUserNum"
				>（{{ imDetails.groupUserNum }}）</span
			>
		</div>
		<div class="rightSet">
			<div
				class="messageUser_header_set"
				@click="switchGroupChat"
				v-if="chatUserMsgActive.sessionType == 'group'"
			>
				<i class="icon-sohu-qiehuanqunzu"></i>
			</div>
			<div
				class="messageUser_header_set"
				@click="messageSetPopoverBox = !messageSetPopoverBox"
			>
				<i class="icon-sohu-ef-gengduo-chuizhi"></i>
			</div>
		</div>
		<transition name="el-zoom-in-top">
			<div class="popoverBox" v-if="messageSetPopoverBox">
				<div
					v-for="item of messageSetList"
					:key="item.name"
					class="popoverBox_item"
					@click="setClick(item)"
				>
					{{ item.name }}
				</div>
			</div>
		</transition>

		<!-- 群设置弹窗 -->
		<GroupSettingsDialog ref="GroupSettingsDialogRef"></GroupSettingsDialog>

		<!-- 名片弹窗 -->
		<businessCardDialog ref="businessCardDialogRef"></businessCardDialog>

		<!-- 切换群聊 -->
		<SwitchGroupChatDialog
			ref="SwitchGroupChatDialogRef"
		></SwitchGroupChatDialog>
	</div>
</template>
<script>
import GroupSettingsDialog from './groupDialog/GroupSettingsDialog.vue';
import businessCardDialog from './userDialog/businessCardDialog.vue';
import SwitchGroupChatDialog from './groupDialog/SwitchGroupChatDialog';
export default {
	components: {
		GroupSettingsDialog,
		businessCardDialog,
		SwitchGroupChatDialog,
	},
	props: ['chatUserMsgActive', 'switchGroup', 'imDetails'],
	data() {
		return {
			messageSetPopoverBox: false,
			messageSetList: [
				{ name: '置顶' },
				{ name: '设置' },
				// { name: '静音' }
			],
		};
	},
	watch: {
		chatUserMsgActive: {
			handler(val) {
				console.log(val);
				if (val.sessionType == 'single') {
					this.messageSetList.splice(2, 1, { name: '删除' });
				} else {
					this.messageSetList.splice(2, 1, { name: '退群' });
				}
			},
			deep: true,
			immediate: true,
		},
	},
	methods: {
		// 设置操作
		setClick({ name }) {
			this.messageSetPopoverBox = false;
			switch (name) {
				case '置顶':
					this.$confirmDialog({
						content: '是否确定置顶该聊天？',
						confirmText: '确认置顶',
						beforeClose: async (action, close) => {
							if (action == 'confirm') {
								const res = await this.$http.chatMessageAsTop(
									this.chatUserMsgActive.sessionType,
									this.chatUserMsgActive.receiver.id,
								);
								if (res.code == 200) {
									this.$message.success('置顶成功');
									close();
									this.$store.dispatch('getUserMessageList');
								}
							}
						},
					});
					break;
				case '设置':
					if (this.chatUserMsgActive.sessionType == 'single') {
						this.$refs.businessCardDialogRef.open(
							this.chatUserMsgActive.receiver.id,
						);
					} else {
						this.$refs.GroupSettingsDialogRef.open(
							this.chatUserMsgActive.receiver.id,
						);
					}
					break;
				case '删除':
					this.$confirmDialog({
						content: '是否确定删除好友？',
						confirmText: '确认删除',
						beforeClose: async (action, close) => {
							if (action == 'confirm') {
								const res = await this.$http.friendsDelete(
									this.chatUserMsgActive.receiver.id,
								);
								if (res.code == 200) {
									this.$message.success('删除成功');
									close();
									this.$store.dispatch('getUserMessageList');
								}
							}
						},
					});
					break;
				case '退群':
					this.$confirmDialog({
						content: '是否退出群聊？',
						confirmText: '确认退出',
						beforeClose: async (action, close) => {
							if (action == 'confirm') {
								const res = await this.$http.imGroupUserRemoveGroup(
									this.chatUserMsgActive.receiver.id,
								);
								if (res.code == 200) {
									this.$message.success('退出成功');
									this.$store.dispatch('getUserMessageList');
								}
								close();
							}
						},
					});
					break;
			}
		},

		// 切换群聊
		switchGroupChat() {
			this.$refs.SwitchGroupChatDialogRef.open(this.chatUserMsgActive);
		},
	},
};
</script>
<style lang="scss" scoped>
.messageUser_header {
	background: #ffffff;
	height: 56px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	box-sizing: border-box;
	padding: 0 16px;
	position: relative;
	.messageUser_header_userName {
		color: #011627;
		font-size: 16px;
		font-weight: 500;
		line-height: 20px;
	}
	.rightSet {
		display: flex;
		justify-content: space-between;
		.messageUser_header_set {
			font-size: 24px;
			cursor: pointer;
			height: 40px;
			width: 40px;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-left: 8px;
			&:hover {
				background: #f3f3f3;
			}
		}
	}

	.popoverBox {
		position: absolute;
		right: 21px;
		top: 46px;
		background-color: #fff;
		width: 76px;
		border-radius: 12px;
		box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.05);
		z-index: 100;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-top: 24px;
		.popoverBox_item {
			font-size: 14px;
			line-height: 22px;
			font-weight: 500;
			margin-bottom: 24px;
			cursor: pointer;
			&:hover {
				color: $theme-color;
			}
		}
	}
}
</style>
